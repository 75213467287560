import React from 'react';
import { connect } from 'react-redux';
import { Paper, Typography, Divider, Button } from '@material-ui/core';

import { OwnerDialog } from '../../../components';
import { actions } from '../../../reducers';

const Owner = ({ owner, dispatch, userID }) => {
  const [open, toggleDialog] = React.useState(false);

  const handleSubmitDialog = (newOwnerID) => {
    dispatch(actions.switchOwner(userID, newOwnerID));
    toggleDialog(false);
  };

  return (
    <React.Fragment>
      <Paper style={{ padding: 16, marginBottom: 32 }}>
        <Typography
          variant="body1"
          style={{ margin: '0 0 16px', fontWeight: 700 }}
        >
          Pemilik Akun
        </Typography>
        <Divider style={{ marginBottom: 16 }} />
        <Typography variant="body1" style={{ fontSize: 14 }}>
          {owner.name} - <em>{owner.email}</em>
        </Typography>
        <Divider style={{ margin: '16px 0' }} />
        <Button
          variant="contained"
          color="primary"
          onClick={() => toggleDialog(true)}
        >
          Ganti Pemilik
        </Button>
      </Paper>
      <OwnerDialog
        open={open}
        currentAuth={owner.id}
        onClose={() => toggleDialog(false)}
        onSelect={handleSubmitDialog}
      />
    </React.Fragment>
  );
};

export default connect()(Owner);
