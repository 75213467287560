import React from 'react';
import { connect } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import {
  Grid,
  Typography,
  Divider,
  Paper,
  TextField,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';
import { DateTimePicker } from '@material-ui/pickers';

import Invite from './Invite';
import { Table } from '../../../components';
import { actions } from '../../../reducers';
import { attendanceColumns } from '../utils';

const DetailKomisi = ({
  dispatch,
  match,
  loading,
  history,
  schedule,
  worships,
  attendances,
  attendancePagination,
}) => {
  const { handleSubmit, register, errors, control, watch } = useForm();
  const [deleteConfirm, showDeleteConfirm] = React.useState(false);
  const [selected, setSelected] = React.useState([]);
  const [inviteModal, showInviteModal] = React.useState(false);
  const [filterModal, setFilterDialog] = React.useState(false);
  const [selectedAttendance, setSelectedAttendance] = React.useState();
  const [deleteConfirmAttendance, showDeleteConfirmAttendance] =
    React.useState(false);
  const [filterType, setFilterType] = React.useState('all');
  const [filterStatus, setFilterStatus] = React.useState('all');

  const [filter, setFilter] = React.useState({
    servant: 0,
    congregation: 0,
    present: 0,
    not_present: 0,
    page: 1,
    per_page: 10,
  });

  React.useEffect(() => {
    dispatch(actions.fetchWorships());
  }, [dispatch]);

  React.useEffect(() => {
    dispatch(actions.fetchAttendances(match.params.id, filter));
  }, [dispatch, match.params.id, filter.page, filter.per_page, filter]);

  React.useEffect(() => {
    dispatch(actions.fetchSchedule(match.params.id));
  }, [dispatch, match.params.id]);

  React.useEffect(() => {
    register({ name: 'worship_id' });
  }, [register]);

  const handlePageChange = (_, page) => {
    setFilter({ ...filter, page: page + 1 });
  };

  const handlePerPageChange = ({ target }) => {
    setFilter({ page: 1, per_page: target.value });
  };

  const onSubmit = (values) => {
    dispatch(
      actions.updateSchedule(match.params.id, {
        ...values,
        max_capacity: values.worship_type === 'RSVP' ? values.max_capacity : 0,
      })
    );
  };

  const onAttend = (userSK) => {
    dispatch(actions.markAsAttend(match.params.id, userSK));
  };

  const onDeleteAttendance = (invID) => {
    const ids = Array.isArray(invID) ? invID : [invID];
    dispatch(actions.deleteAttendance(match.params.id, ids));
  };

  const handleFilter = () => {
    setFilter({
      servant: filterType === 'servant' ? 1 : 0,
      congregation: filterType === 'congregation' ? 1 : 0,
      present: filterStatus === 'present' ? 1 : 0,
      not_present: filterStatus === 'not_present' ? 1 : 0,
    });
  };

  const handleClose = () => setFilterDialog(false);

  return (
    <React.Fragment>
      <Grid container spacing={2} style={{ marginTop: 8 }}>
        <Grid item md={4}>
          {schedule ? (
            <Paper style={{ padding: 16 }}>
              <Typography
                variant="body1"
                style={{ margin: '0 0 16px', fontWeight: 700 }}
              >
                Ubah Data
              </Typography>
              <Divider style={{ marginBottom: 32 }} />
              <form onSubmit={handleSubmit(onSubmit)}>
                <div style={{ marginBottom: 16 }}>
                  <FormControl variant="outlined" style={{ width: '100%' }}>
                    <InputLabel>Kebaktian</InputLabel>
                    <Controller
                      as={
                        <Select variant="outlined" label="Kebaktian">
                          {worships.map((w) => (
                            <MenuItem key={w.id} value={w.id}>
                              {w.name}
                            </MenuItem>
                          ))}
                        </Select>
                      }
                      name="worship_id"
                      control={control}
                      onChange={([selected]) => {
                        return selected.target.value;
                      }}
                      defaultValue={schedule.worship_id}
                      value={schedule.worship_id}
                    />
                  </FormControl>
                </div>
                <div style={{ marginBottom: 16 }}>
                  <TextField
                    fullWidth
                    label="Deskripsi"
                    name="description"
                    size="small"
                    variant="outlined"
                    multiline
                    rows={4}
                    defaultValue={schedule.description}
                    inputRef={register({ required: true })}
                    error={errors.hasOwnProperty('description')}
                  />
                </div>
                <div style={{ marginBottom: 16 }}>
                  <InputLabel shrink>Tipe Absensi</InputLabel>
                  <Controller
                    as={
                      <Select fullWidth variant="outlined">
                        <MenuItem value="RSVP">Reservasi</MenuItem>
                        <MenuItem value="ONSITE">Onsite</MenuItem>
                      </Select>
                    }
                    name="worship_type"
                    control={control}
                    onChange={([selected]) => {
                      return selected.target.value;
                    }}
                    defaultValue={schedule.worship_type}
                  />
                </div>
                {watch('worship_type') === 'RSVP' ? (
                  <div style={{ marginBottom: 16 }}>
                    <TextField
                      fullWidth
                      label="Kapasitas"
                      name="max_capacity"
                      size="small"
                      type="number"
                      variant="outlined"
                      inputRef={register({ required: true })}
                      error={errors.hasOwnProperty('max_capacity')}
                      defaultValue={schedule.max_capacity}
                    />
                  </div>
                ) : null}
                <div style={{ marginBottom: 16 }}>
                  <Controller
                    as={
                      <DateTimePicker
                        inputVariant="outlined"
                        style={{ width: '100%' }}
                        ampm={false}
                        margin="normal"
                        label="Jadwal Kebaktian"
                        minDate={new Date()}
                        format="dd MMM yyyy HH:mm"
                      />
                    }
                    name="started_at"
                    control={control}
                    defaultValue={new Date(schedule.started_at)}
                  />
                </div>
                <Divider />
                <div style={{ margin: '16px 0' }}>
                  <Button
                    onClick={() => history.push('/d/jadwal')}
                    style={{ marginRight: 8 }}
                    variant="outlined"
                  >
                    Kembali
                  </Button>
                  <Button variant="contained" color="primary" type="submit">
                    Simpan
                  </Button>
                </div>
              </form>
            </Paper>
          ) : null}
          <Paper style={{ padding: 16, marginTop: 16 }}>
            <Typography
              variant="body1"
              style={{ margin: '0 0 16px', fontWeight: 700 }}
            >
              Hapus
            </Typography>
            <Divider />
            <div style={{ margin: '8px 0' }}>
              <Typography variant="caption">
                Menghapus jadwal akan menghapus semua daftar hadir.
              </Typography>
            </div>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                showDeleteConfirm(true);
              }}
            >
              Hapus Jadwal
            </Button>
            <Dialog
              open={deleteConfirm}
              onClose={() => showDeleteConfirm(false)}
            >
              <DialogTitle id="alert-dialog-title">Hapus Jadwal</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Anda yakin akan menghapus jadwal ini?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => showDeleteConfirm(false)}
                  color="primary"
                >
                  Batal
                </Button>
                <Button
                  onClick={() => {
                    dispatch(actions.deleteSchedule(match.params.id, history));
                  }}
                  color="primary"
                  autoFocus
                >
                  Hapus
                </Button>
              </DialogActions>
            </Dialog>
          </Paper>
        </Grid>
        <Grid item md={8}>
          <Paper style={{ padding: 16 }}>
            <Typography
              variant="body1"
              style={{ margin: '0 0 16px', fontWeight: 700 }}
            >
              Daftar Hadir
            </Typography>
            <Divider style={{ marginBottom: 32 }} />
            <Table
              dense
              stickyHeader
              data={attendances}
              columns={attendanceColumns({
                setAttendance: (record) => setSelectedAttendance(record),
              })}
              getSelectedRow={(rows) => setSelected(rows.map((r) => r.id))}
              header={
                <div style={{ marginBottom: 16 }}>
                  <Button
                    color="secondary"
                    variant="contained"
                    size="small"
                    disabled={selected.length === 0}
                    onClick={() => showDeleteConfirmAttendance(true)}
                  >
                    Hapus dari Jadwal
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    size="small"
                    style={{ marginLeft: 8 }}
                    onClick={() => showInviteModal(true)}
                  >
                    Undang
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    size="small"
                    style={{ marginLeft: 8 }}
                    onClick={() => setFilterDialog(true)}
                  >
                    Filter
                  </Button>
                </div>
              }
              pagination={{
                perPageOptions: [10, 25, 50, 100],
                perPage: +filter.per_page || 10,
                page: filter.page ? +filter.page - 1 : 0,
                count: attendancePagination ? attendancePagination.count : 1,
                onChangePage: handlePageChange,
                onChangePerPage: handlePerPageChange,
              }}
            />
            <Invite
              scheduleID={match.params.id}
              open={inviteModal}
              handleClose={() => showInviteModal(false)}
            />

            <Dialog onClose={handleClose} open={filterModal} fullWidth>
              <DialogTitle
                onClose={handleClose}
                style={{ padding: '8px 16px' }}
              >
                Filter Kehadiran
              </DialogTitle>
              <DialogContent dividers style={{ padding: 16 }}>
                <FormControl style={{ width: '100%', marginBottom: 16 }}>
                  <InputLabel shrink>Tipe Kehadiran</InputLabel>
                  <Select
                    value={filterType}
                    onChange={(e) => {
                      setFilterType(e.target.value);
                    }}
                  >
                    <MenuItem value="all">Semua</MenuItem>
                    <MenuItem value="congregation">Jemaat</MenuItem>
                    <MenuItem value="servant">Pelayan</MenuItem>
                  </Select>
                </FormControl>
                <FormControl style={{ width: '100%' }}>
                  <InputLabel shrink>Status</InputLabel>
                  <Select
                    value={filterStatus}
                    onChange={(e) => {
                      setFilterStatus(e.target.value);
                    }}
                  >
                    <MenuItem value="all">Semua</MenuItem>
                    <MenuItem value="not_present">Belum Hadir</MenuItem>
                    <MenuItem value="present">Sudah Hadir</MenuItem>
                  </Select>
                </FormControl>
              </DialogContent>
              <DialogActions>
                <Button
                  variant="contained"
                  onClick={() => {
                    setFilterStatus('all');
                    setFilterType('all');
                    setFilterDialog(false);
                    setFilter({
                      servant: null,
                      congregation: null,
                      present: null,
                      not_present: null,
                    });
                  }}
                  style={{ margin: '0 8px' }}
                >
                  Reset
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    handleFilter();
                    handleClose();
                  }}
                  style={{ margin: 0 }}
                >
                  Terapkan
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              open={deleteConfirmAttendance}
              onClose={() => showDeleteConfirm(false)}
            >
              <DialogTitle id="alert-dialog-title">Hapus Kehadiran</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Anda yakin akan menghapus data kehadiran?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => showDeleteConfirmAttendance(false)}
                  color="primary"
                >
                  Batal
                </Button>
                <Button
                  onClick={() => {
                    onDeleteAttendance(selected);
                    showDeleteConfirmAttendance(false);
                    setSelected([]);
                  }}
                  color="primary"
                  autoFocus
                >
                  Hapus
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              open={selectedAttendance}
              onClose={() => showDeleteConfirm(false)}
            >
              <DialogTitle id="alert-dialog-title">
                Manajemen Kehadiran
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {selectedAttendance?.arrived_at
                    ? `Hapus kehadiran "${selectedAttendance?.user.name}"?`
                    : `Tandai "${selectedAttendance?.user.name}" sebagai hadir?`}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setSelectedAttendance()} color="primary">
                  Batal
                </Button>
                <Button
                  onClick={() => {
                    if (selectedAttendance?.arrived_at) {
                      onDeleteAttendance(selectedAttendance?.id);
                    } else {
                      onAttend(selectedAttendance?.user?.user_sk);
                    }
                    setSelectedAttendance(null);
                  }}
                  variant="contained"
                  color="primary"
                  autoFocus
                >
                  {selectedAttendance?.arrived_at ? 'Hapus' : 'Tandai Hadir'}
                </Button>
              </DialogActions>
            </Dialog>
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default connect((state) => {
  return {
    loading: state.schedule.loading,
    schedule: state.schedule.detail,
    worships: state.worship.data,
    attendances: state.attendance.data,
    attendancePagination: state.attendance.pagination,
  };
})(DetailKomisi);
