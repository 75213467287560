import React from 'react';
import get from 'lodash/get';
import { useCookies } from 'react-cookie';

import config from '../../config';
import { getRole } from '../../utils/token';
import api from '../../utils/api';

const useLogin = ({ history }) => {
  const [error, setError] = React.useState(null);
  const [, setCookie] = useCookies([config.COOKIE_NAME]);
  const [isOpen, toggleSnackbar] = React.useState(false);

  const callback = React.useCallback(
    (res) => {
      const authCode = get(res, 'code', null);
      if (!authCode) {
        console.error('Auth code not found.');
        return;
      }

      api
        .post('/auth/', {
          id_token: authCode,
          church_id: null,
        })
        .then(({ data: res }) => {
          if (res.hasOwnProperty('token')) {
            const today = new Date();
            const nextTenYear = new Date();
            nextTenYear.setFullYear(today.getFullYear() + 10);

            setCookie('access_token', res.token, {
              expires: nextTenYear,
            });
            const role = getRole(res.token);
            if (role === 'ADMIN') {
              setTimeout(() => {
                history.push('/d');
              }, 500);
            } else {
              toggleSnackbar(true);
              setError('Anda tidak memiliki akses');
            }
          } else {
            toggleSnackbar(true);
          }
        })
        .catch((err) => {
          toggleSnackbar(true);
          setError(err.message);
        });
    },
    [history, setCookie],
  );

  return [callback, isOpen, toggleSnackbar, error];
};

export default useLogin;
