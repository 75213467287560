import React from 'react';
import { connect } from 'react-redux';
import {
  Paper,
  Grid,
  Typography,
  TextField,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  Divider,
  Button,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import s from 'styled-components';
import FilterListIcon from '@material-ui/icons/FilterList';

import { Table } from '../../../components';
import { actions } from '../../../reducers';
import { buildQueryString } from '../../../utils/url';
import { columns } from '../utils';
import api from '../../../utils/api';

const MetaWrapper = s.div`
  padding: 16px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const FilterWrapper = s.div`
  padding: 16px;
`;

const useAutocompleteLocation = () => {
  const [loading, setLoading] = React.useState(false);
  const [query, setQuery] = React.useState('');
  const [options, setOptions] = React.useState([]);

  React.useEffect(() => {
    setLoading(true);
    api
      .get(`/location/district?q=${query}`)
      .then(({ data: res }) => {
        setOptions(res.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [query]);

  return [options, setQuery, loading];
};

const ListJemaat = ({
  history,
  dispatch,
  loading,
  users,
  query,
  pagination,
  commisions,
}) => {
  const [showFilter, toggleFilter] = React.useState(false);
  const [district, setDistrict] = useAutocompleteLocation();
  const [filter, setFilter] = React.useState({
    page: +query.get('page') || 1,
    per_page: +query.get('per_page') || 10,
    name: query.get('name') || '',
    nickname: query.get('nickname') || '',
    phone: query.get('phone') || '',
    gender: query.get('gender') || '',
    month: query.get('month') || '',
    membership: query.get('membership') || '',
    marital_status: query.get('marital_status') || '',
    location: query.get('location') || '',
    min_age: query.get('min_age') || '',
    max_age: query.get('max_age') || '',
    baptise: query.get('baptise') || '',
    min_join: query.get('min_join') || '',
    is_dead: query.get('is_dead') || '',
    is_c19_vaccinated: query.get('is_c19_vaccinated') || '',
    blood_type: query.get('blood_type') || '',
    available_for_donor: query.get('available_for_donor') || '',
  });

  React.useEffect(() => {
    dispatch(actions.fetchCommisions({ per_page: 100 }));
    // eslint-disable-next-line
  }, []);

  const handlePageChange = (_, page) => {
    setFilter({ ...filter, page: page + 1 });
  };

  const handlePerPageChange = ({ target }) => {
    setFilter({ ...filter, page: 1, per_page: target.value });
  };

  React.useEffect(() => {
    dispatch(actions.fetchUsers(filter));
    history.push(`/d/jemaat?${buildQueryString(filter)}`);
    // eslint-disable-next-line
  }, [filter.page, filter.per_page]);

  return (
    <React.Fragment>
      <MetaWrapper>
        <Typography variant="h5" style={{ margin: '16px 0' }}>
          Jemaat
        </Typography>
        <Button
          color="primary"
          variant="contained"
          onClick={() => toggleFilter(!showFilter)}
          startIcon={<FilterListIcon />}
        >
          {!showFilter ? 'Tampilkan Filter' : 'Sembunyikan Filter'}
        </Button>
      </MetaWrapper>
      {showFilter ? (
        <Paper variant="outlined" style={{ marginBottom: 32 }}>
          <Typography variant="body1" style={{ fontWeight: 700, margin: 16 }}>
            Filter data berdasarkan:
          </Typography>
          <Divider />
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <FilterWrapper>
                <TextField
                  size="small"
                  fullWidth
                  label="Nama Lengkap"
                  value={filter.name}
                  onChange={({ target }) =>
                    setFilter({ ...filter, name: target.value })
                  }
                />
              </FilterWrapper>
              <FilterWrapper>
                <TextField
                  size="small"
                  fullWidth
                  label="Nama Panggilan"
                  value={filter.nickname}
                  onChange={({ target }) =>
                    setFilter({ ...filter, nickname: target.value })
                  }
                />
              </FilterWrapper>
              <FilterWrapper>
                <Autocomplete
                  getOptionLabel={(option) => option.location}
                  options={district}
                  autoComplete
                  includeInputInList
                  onChange={(_, value) => {
                    if (value) {
                      setFilter({
                        ...filter,
                        location: value.detail.map((d) => d.name).join(','),
                      });
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Kecamatan"
                      size="small"
                      fullWidth
                      onChange={({ target }) => {
                        setDistrict(target.value);
                      }}
                    />
                  )}
                />
              </FilterWrapper>
              <FilterWrapper>
                <FormControl fullWidth>
                  <InputLabel id="bulan-kelahiran">Bulan Kelahiran</InputLabel>
                  <Select
                    labelId="bulan-kelahiran"
                    value={filter.month}
                    onChange={({ target }) =>
                      setFilter({ ...filter, month: target.value })
                    }
                  >
                    <MenuItem value="">Pilih bulan</MenuItem>
                    <MenuItem value="1">Januari</MenuItem>
                    <MenuItem value="2">Februari</MenuItem>
                    <MenuItem value="3">Maret</MenuItem>
                    <MenuItem value="4">April</MenuItem>
                    <MenuItem value="5">Mei</MenuItem>
                    <MenuItem value="6">Juni</MenuItem>
                    <MenuItem value="7">Juli</MenuItem>
                    <MenuItem value="8">Agustus</MenuItem>
                    <MenuItem value="9">Sept</MenuItem>
                    <MenuItem value="10">Oktober</MenuItem>
                    <MenuItem value="11">November</MenuItem>
                    <MenuItem value="12">Desember</MenuItem>
                  </Select>
                </FormControl>
              </FilterWrapper>
              <FilterWrapper>
                <FormControl fullWidth>
                  <InputLabel id="is_dead">Hidup/Meninggal</InputLabel>
                  <Select
                    labelId="is_dead"
                    value={filter.is_dead}
                    onChange={({ target }) =>
                      setFilter({ ...filter, is_dead: target.value })
                    }
                  >
                    <MenuItem value="">Pilih tipe</MenuItem>
                    <MenuItem value="1">Meninggal</MenuItem>
                    <MenuItem value="0">Hidup</MenuItem>
                  </Select>
                </FormControl>
              </FilterWrapper>
            </Grid>

            <Grid item xs={4}>
              <FilterWrapper>
                <TextField
                  size="small"
                  fullWidth
                  label="No Handphone"
                  type="number"
                  value={filter.phone}
                  onChange={({ target }) =>
                    setFilter({ ...filter, phone: target.value })
                  }
                />
              </FilterWrapper>

              <FilterWrapper>
                <FormControl fullWidth>
                  <InputLabel id="jenis-kelamin">Jenis Kelamin</InputLabel>
                  <Select
                    labelId="jenis-kelamin"
                    value={filter.gender}
                    onChange={({ target }) =>
                      setFilter({ ...filter, gender: target.value })
                    }
                  >
                    <MenuItem value="">Pilih jenis kelamin</MenuItem>
                    <MenuItem value="MALE">Laki-Laki</MenuItem>
                    <MenuItem value="FEMALE">Perempuan</MenuItem>
                  </Select>
                </FormControl>
              </FilterWrapper>

              <FilterWrapper>
                <div
                  style={{
                    display: 'flex',
                    flexFlow: 'row wrap',
                    justifyContent: 'space-between',
                  }}
                >
                  <TextField
                    size="small"
                    fullWidth
                    label="Umur Minimal"
                    type="number"
                    style={{ width: '48%' }}
                    value={filter.min_age}
                    onChange={({ target }) =>
                      setFilter({ ...filter, min_age: target.value })
                    }
                  />
                  <TextField
                    size="small"
                    fullWidth
                    label="Umur Maksimal"
                    type="number"
                    style={{ width: '48%' }}
                    value={filter.max_age}
                    onChange={({ target }) =>
                      setFilter({ ...filter, max_age: target.value })
                    }
                  />
                </div>
              </FilterWrapper>

              <FilterWrapper>
                <TextField
                  size="small"
                  fullWidth
                  label="Lama Tahun Bergabung"
                  type="number"
                  value={filter.min_join}
                  onChange={({ target }) =>
                    setFilter({ ...filter, min_join: target.value })
                  }
                />
              </FilterWrapper>

              <FilterWrapper>
                <FormControl fullWidth>
                  <InputLabel id="blood_type">Golongan Darah</InputLabel>
                  <Select
                    labelId="blood_type"
                    value={filter.blood_type}
                    onChange={({ target }) =>
                      setFilter({ ...filter, blood_type: target.value })
                    }
                  >
                    <MenuItem value="">Pilih golongan Darah</MenuItem>
                    <MenuItem value="A">A</MenuItem>
                    <MenuItem value="B">B</MenuItem>
                    <MenuItem value="O">O</MenuItem>
                    <MenuItem value="AB">AB</MenuItem>
                  </Select>
                </FormControl>
              </FilterWrapper>
            </Grid>
            <Grid item xs={4}>
              <FilterWrapper>
                <FormControl fullWidth>
                  <InputLabel id="membership">Keanggotaan</InputLabel>
                  <Select
                    labelId="membership"
                    value={filter.membership}
                    onChange={({ target }) =>
                      setFilter({ ...filter, membership: target.value })
                    }
                  >
                    <MenuItem value="">Pilih tipe keanggotaan</MenuItem>
                    <MenuItem value="PERMANENT">Jemaat Tetap</MenuItem>
                    <MenuItem value="TEMPORARY">Simpatisan</MenuItem>
                  </Select>
                </FormControl>
              </FilterWrapper>
              <FilterWrapper>
                <FormControl fullWidth>
                  <InputLabel id="is_c19_vaccinated">Status Vaksin</InputLabel>

                  <Select
                    labelId="membership"
                    value={filter.is_c19_vaccinated}
                    onChange={({ target }) =>
                      setFilter({ ...filter, is_c19_vaccinated: target.value })
                    }
                  >
                    <MenuItem value="">Pilih Status Vaksin</MenuItem>
                    <MenuItem value="1">Sudah Vaksin</MenuItem>
                    <MenuItem value="0">Belum Vaksin</MenuItem>
                  </Select>
                </FormControl>
              </FilterWrapper>
              <FilterWrapper>
                <FormControl fullWidth>
                  <InputLabel id="marital_status">Status Perkawinan</InputLabel>
                  <Select
                    labelId="marital_status"
                    value={filter.marital_status}
                    onChange={({ target }) =>
                      setFilter({ ...filter, marital_status: target.value })
                    }
                  >
                    <MenuItem value="">Pilih status perkawinan</MenuItem>
                    <MenuItem value="SINGLE">Belum Menikah</MenuItem>
                    <MenuItem value="MARRIED">Menikah</MenuItem>
                    <MenuItem value="WIDOWER">Duda</MenuItem>
                    <MenuItem value="WIDOWED">Janda</MenuItem>
                    <MenuItem value="DIVORCED">Cerai</MenuItem>
                  </Select>
                </FormControl>
              </FilterWrapper>

              <FilterWrapper>
                <FormControl fullWidth>
                  <InputLabel id="commision">Persekutuan</InputLabel>
                  <Select
                    labelId="commision"
                    value={filter.commision}
                    onChange={({ target }) =>
                      setFilter({ ...filter, commision: target.value })
                    }
                  >
                    <MenuItem value="">Pilih Persekutuan</MenuItem>
                    <MenuItem value="-1">Belum Mengikuti Persekutuan</MenuItem>
                    {commisions.map((cm) => (
                      <MenuItem key={cm.id} value={cm.id || ''}>
                        {cm.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </FilterWrapper>

              <FilterWrapper>
                <FormControl fullWidth>
                  <InputLabel id="is_baptised">Status Baptis</InputLabel>

                  <Select
                    labelId="membership"
                    value={filter.baptise}
                    onChange={({ target }) =>
                      setFilter({ ...filter, baptise: target.value })
                    }
                  >
                    <MenuItem value="">Pilih Status Baptis</MenuItem>
                    <MenuItem value="1">Sudah Baptis</MenuItem>
                    <MenuItem value="0">Belum Baptis</MenuItem>
                  </Select>
                </FormControl>
              </FilterWrapper>
              <FilterWrapper>
                <FormControl fullWidth>
                  <InputLabel id="available_for_donor">
                    Bersedia Donor Darah
                  </InputLabel>
                  <Select
                    labelId="available_for_donor"
                    value={filter.available_for_donor}
                    onChange={({ target }) =>
                      setFilter({
                        ...filter,
                        available_for_donor: target.value,
                      })
                    }
                  >
                    <MenuItem value="0">Tidak Bersedia</MenuItem>
                    <MenuItem value="1">Bersedia</MenuItem>
                  </Select>
                </FormControl>
              </FilterWrapper>
            </Grid>
            <Grid item xs={12}>
              <Divider />
              <FilterWrapper>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    dispatch(actions.fetchUsers(filter));
                    history.push(`/d/jemaat?${buildQueryString(filter)}`);
                  }}
                >
                  Terapkan Filter
                </Button>
                <Button
                  style={{ marginLeft: 5 }}
                  color="secondary"
                  variant="contained"
                  onClick={() => {
                    setFilter({
                      name: '',
                      nickname: '',
                      phone: '',
                      gender: '',
                      membership: '',
                      marital_status: '',
                      blood_type: '',
                      available_for_donor: '',
                    });
                    history.push('/d/jemaat');
                  }}
                >
                  Reset Filter
                </Button>
              </FilterWrapper>
            </Grid>
          </Grid>
        </Paper>
      ) : null}
      <Table
        dense
        data={users}
        columns={columns}
        stickyHeader
        onRow={{
          onClick: (_, row) => history.push(`/d/jemaat/${row.id}`),
        }}
        pagination={{
          perPageOptions: [10, 25, 50, 100],
          perPage: +query.get('per_page') || 10,
          page: query.get('page') ? +query.get('page') - 1 : 0,
          count: pagination ? pagination.count : 1,
          onChangePage: handlePageChange,
          onChangePerPage: handlePerPageChange,
        }}
      />
    </React.Fragment>
  );
};

export default connect((state, props) => {
  const query = new URLSearchParams(props.location.search);
  return {
    commisions: state.commision.data,
    users: state.user.data,
    loading: state.user.loading,
    query: query,
    pagination: state.user.pagination,
  };
})(ListJemaat);
