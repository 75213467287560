import React from 'react';
import { useCookies } from 'react-cookie';
import { withRouter } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Typography,
  List,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Collapse,
  Drawer,
} from '@material-ui/core';
import ExitToApp from '@material-ui/icons/ExitToApp';
import AppsIcon from '@material-ui/icons/Apps';
import MapIcon from '@material-ui/icons/Map';
import PersonIcon from '@material-ui/icons/Person';
import GroupIcon from '@material-ui/icons/Group';
import MenuIcon from '@material-ui/icons/Menu';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import EventIcon from '@material-ui/icons/Event';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import HighlightIcon from '@material-ui/icons/Highlight';
import PollIcon from '@material-ui/icons/Poll';
import s from 'styled-components';

import config from '../../config';

const Content = s.div`
  min-height: 70vh;
  padding: 64px 16px 16px;
`;

const PrimaryNavigation = ({ history, children }) => {
  const [, , removeCookie] = useCookies([config.COOKIE_NAME]);
  const [open, setOpen] = React.useState(false);
  const [collapse, setCollapse] = React.useState('');

  return (
    <React.Fragment>
      <AppBar position="fixed" style={{ zIndex: 1301 }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={() => setOpen(!open)}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6">Gepembri</Typography>
        </Toolbar>
      </AppBar>
      <Drawer anchor="left" open={open} onClose={() => setOpen(false)}>
        <Toolbar />
        <List component="nav">
          <ListItem
            button
            onClick={() => {
              history.push('/d');
              setOpen(false);
            }}
          >
            <ListItemIcon>
              <AppsIcon />
            </ListItemIcon>
            <ListItemText primary="Beranda" />
          </ListItem>
          <ListItem
            button
            onClick={() => {
              history.push('/d/maps');
              setOpen(false);
            }}
          >
            <ListItemIcon>
              <MapIcon />
            </ListItemIcon>
            <ListItemText primary="Pemetaan" />
          </ListItem>
        </List>
        <Divider />
        <List component="nav" aria-label="Table menu">
          <ListItem
            button
            onClick={() => {
              history.push('/d/jemaat');
              setOpen(false);
            }}
          >
            <ListItemIcon>
              <PersonIcon />
            </ListItemIcon>
            <ListItemText primary="Jemaat" />
          </ListItem>
          <ListItem
            button
            onClick={() => {
              history.push('/d/komisi');
              setOpen(false);
            }}
          >
            <ListItemIcon>
              <GroupIcon />
            </ListItemIcon>
            <ListItemText primary="Persekutuan" />
          </ListItem>
          <ListItem
            button
            onClick={() => {
              setCollapse(collapse ? null : 'ibadah');
            }}
          >
            <ListItemIcon>
              <HomeWorkIcon />
            </ListItemIcon>
            <ListItemText primary="Ibadah" />
            {collapse === 'ibadah' ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={collapse === 'ibadah'} unmountOnExit={false}>
            <div style={{ marginLeft: 32 }}>
              <List component="div" disablePadding>
                <ListItem
                  button
                  onClick={() => {
                    history.push('/d/kebaktian');
                    setOpen(false);
                  }}
                >
                  <ListItemIcon>
                    <HighlightIcon />
                  </ListItemIcon>
                  <ListItemText primary="Kebaktian" />
                </ListItem>
              </List>

              <List component="div" disablePadding>
                <ListItem
                  button
                  onClick={() => {
                    history.push('/d/jadwal');
                    setOpen(false);
                  }}
                >
                  <ListItemIcon>
                    <EventIcon />
                  </ListItemIcon>
                  <ListItemText primary="Jadwal" />
                </ListItem>
              </List>
            </div>
          </Collapse>
        </List>
        <ListItem
          button
          onClick={() => {
            history.push('/d/poll-namespace');
            setOpen(false);
          }}
        >
          <ListItemIcon>
            <PollIcon />
          </ListItemIcon>
          <ListItemText primary="Polling" />
        </ListItem>
        <Divider />
        <ListItem
          button
          onClick={() => {
            removeCookie('access_token', { path: '/' });
            setTimeout(() => {
              history.push('/');
            }, 100);
          }}
        >
          <ListItemIcon>
            <ExitToApp />
          </ListItemIcon>
          <ListItemText primary="Keluar" />
        </ListItem>
      </Drawer>
      <Content>{children}</Content>
    </React.Fragment>
  );
};

export default withRouter(PrimaryNavigation);
