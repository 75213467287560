import api from '../../utils/api';
import * as c from './constants';

const defaultParams = {
  page: 1,
  per_page: 10,
};

export const fetchVotes = (pollID, optionID, filter = defaultParams) => {
  return async (dispatch) => {
    try {
      const mapper = {
        per_page: 'limit',
      };

      const query = Object.keys(filter)
        .filter((k) => filter[k])
        .map((k) => `${mapper.hasOwnProperty(k) ? mapper[k] : k}=${filter[k]}`)
        .join('&');

      dispatch({ type: c.VOTE_LOADING });
      const { data: res } = await api.get(
        `/polls/${pollID}/options/${optionID}/votes?${query}`,
      );
      dispatch({
        type: c.VOTES_FULFILLED,
        payload: res.data,
        pagination: res.pagination,
      });
    } catch (error) {
      console.error(error);
      dispatch({ type: c.VOTES_FULFILLED, payload: [] });
    }
  };
};
