import api from '../../utils/api';
import * as c from './constants';
import { toggleSnackbar } from '../ui/actions';

const defaultParams = {
  page: 1,
  per_page: 10,
  worship: '',
  description: '',
  is_coming: null,
};

export const fetchSchedules = (filter = defaultParams) => {
  return async (dispatch) => {
    try {
      const mapper = {
        per_page: 'limit',
      };

      const query = Object.keys(filter)
        .filter((k) => filter[k])
        .map((k) => `${mapper.hasOwnProperty(k) ? mapper[k] : k}=${filter[k]}`)
        .join('&');

      dispatch({ type: c.SCHEDULE_LOADING });
      const { data: res } = await api.get(`/schedules/?${query}`);
      dispatch({
        type: c.SCHEDULES_FULFILLED,
        payload: res.data,
        pagination: res.pagination,
      });
    } catch (error) {
      console.error(error);
      dispatch({ type: c.SCHEDULES_FULFILLED, payload: [] });
    }
  };
};

export const fetchSchedule = (scheduleID) => {
  return async (dispatch) => {
    try {
      dispatch({ type: c.SCHEDULE_LOADING });
      const { data: res } = await api.get(`/schedules/${scheduleID}`);
      dispatch({ type: c.SCHEDULE_FULFILLED, payload: res.data });
    } catch (error) {
      console.error(error);
      dispatch({ type: c.SCHEDULE_FULFILLED, payload: null });
    }
  };
};

export const createSchedule = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: c.SCHEDULE_LOADING });
      await api.post('/schedules/', data);
      dispatch(fetchSchedules());
      dispatch(toggleSnackbar('Data berhasil disimpan.'));
    } catch (error) {
      console.error(error);
      dispatch(toggleSnackbar('Gagal menyimpan data.'));
    }
  };
};

export const updateSchedule = (scheduleID, data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: c.SCHEDULE_LOADING });
      await api.put(`/schedules/${scheduleID}`, data);
      dispatch(fetchSchedule(scheduleID));
      dispatch(toggleSnackbar('Data berhasil disimpan.'));
    } catch (error) {
      console.error(error);
      dispatch(toggleSnackbar('Gagal menyimpan data.'));
    }
  };
};

export const deleteSchedule = (scheduleID, history) => {
  return async (dispatch) => {
    try {
      await api.delete('/schedules', {
        data: [scheduleID],
      });

      dispatch(toggleSnackbar('Data berhasil dihapus.'));
      history.push('/d/jadwal');
    } catch (error) {
      console.error(error);
      dispatch(toggleSnackbar('Gagal menghapus data.'));
    }
  };
};
