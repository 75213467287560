import api from '../../utils/api';
import * as c from './constants';

export const fetchUserRelationships = (userID) => {
  return async (dispatch) => {
    try {
      dispatch({ type: c.USER_RELATIONSHIP_LOADING });
      const { data: res } = await api.get(`/relationships/${userID}`);
      dispatch({ type: c.USER_RELATIONSHIPS_FULFILLED, payload: res.data });
    } catch (error) {
      console.error(error);
      dispatch({ type: c.USER_RELATIONSHIPS_FULFILLED, payload: [] });
    }
  };
};
