import React from 'react';
import { connect } from 'react-redux';
import {
  Paper,
  Grid,
  Typography,
  TextField,
  Button,
  Divider,
} from '@material-ui/core';
import s from 'styled-components';
import FilterListIcon from '@material-ui/icons/FilterList';
import AddIcon from '@material-ui/icons/Add';

import { Table } from '../../../components';
import { actions } from '../../../reducers';
import { buildQueryString } from '../../../utils/url';
import { columns } from '../utils';

import AddCommision from '../Add';

const MetaWrapper = s.div`
  padding: 16px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const FilterWrapper = s.div`
  padding: 16px;
`;

const ListKomisi = ({
  history,
  dispatch,
  loading,
  commisions,
  query,
  pagination,
}) => {
  const [showFilter, toggleFilter] = React.useState(false);
  const [showAddModal, toggleAddModal] = React.useState(false);
  const [filter, setFilter] = React.useState({
    page: +query.get('page') || 1,
    per_page: +query.get('per_page') || 10,
    name: query.get('name') || '',
  });

  const handlePageChange = (_, page) => {
    setFilter({ ...filter, page: page + 1 });
  };

  const handlePerPageChange = ({ target }) => {
    setFilter({ ...filter, page: 1, per_page: target.value });
  };

  React.useEffect(() => {
    dispatch(actions.fetchCommisions(filter));
    history.push(`/d/komisi?${buildQueryString(filter)}`);
    // eslint-disable-next-line
  }, [filter.page, filter.per_page]);

  return (
    <React.Fragment>
      <MetaWrapper>
        <Typography variant="h5" style={{ margin: '16px 0' }}>
          Persekutuan
        </Typography>
        <div>
          <Button
            color="primary"
            variant="contained"
            onClick={() => toggleFilter(!showFilter)}
            startIcon={<FilterListIcon />}
          >
            {!showFilter ? 'Tampilkan Filter' : 'Sembunyikan Filter'}
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => toggleAddModal(true)}
            startIcon={<AddIcon />}
            style={{ marginLeft: 8 }}
          >
            Tambah
          </Button>
        </div>
      </MetaWrapper>
      {showFilter ? (
        <Paper variant="outlined" style={{ marginBottom: 32 }}>
          <Typography variant="body1" style={{ margin: 16, fontWeight: 700 }}>
            Filter data berdasarkan:
          </Typography>
          <Divider />
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <FilterWrapper>
                <TextField
                  size="small"
                  fullWidth
                  id="standard-basic"
                  label="Nama"
                  value={filter.name}
                  onChange={({ target }) =>
                    setFilter({ ...filter, name: target.value })
                  }
                />
              </FilterWrapper>
            </Grid>
            <Grid item xs={12}>
              <Divider />
              <FilterWrapper>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    dispatch(actions.fetchCommisions(filter));
                    history.push(`/d/komisi?${buildQueryString(filter)}`);
                  }}
                >
                  Terapkan Filter
                </Button>
                <Button
                  style={{ marginLeft: 5 }}
                  color="secondary"
                  variant="contained"
                  onClick={() => {
                    setFilter({
                      name: '',
                      nickname: '',
                      phone: '',
                      gender: '',
                      membership: '',
                      marital_status: '',
                    });
                    history.push('/d/jemaat');
                  }}
                >
                  Reset Filter
                </Button>
              </FilterWrapper>
            </Grid>
          </Grid>
        </Paper>
      ) : null}
      <Table
        dense
        stickyHeader
        data={commisions}
        columns={columns}
        onRow={{
          onClick: (_, row) => history.push(`/d/komisi/${row.id}`),
        }}
        pagination={{
          perPageOptions: [10, 25, 50, 100],
          perPage: +query.get('per_page') || 10,
          page: query.get('page') ? +query.get('page') - 1 : 0,
          count: pagination ? pagination.count : 1,
          onChangePage: handlePageChange,
          onChangePerPage: handlePerPageChange,
        }}
      />
      <AddCommision
        open={showAddModal}
        handleClose={() => toggleAddModal(false)}
      />
    </React.Fragment>
  );
};

export default connect((state, props) => {
  const query = new URLSearchParams(props.location.search);
  return {
    commisions: state.commision.data,
    loading: state.commision.loading,
    query: query,
    pagination: state.commision.pagination,
  };
})(ListKomisi);
