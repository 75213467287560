import React from 'react';
import { connect } from 'react-redux';
import { useForm } from 'react-hook-form';
import {
  Grid,
  Typography,
  Divider,
  Avatar,
  Paper,
  TextField,
  Button,
  FormHelperText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import { Table } from '../../../components';
import uploadImage from '../../../utils/uploadImage';
import { actions } from '../../../reducers';
import { memberColumns } from '../utils';
import { getImage } from '../../../utils/image';

const DetailKomisi = ({
  dispatch,
  match,
  loading,
  history,
  commision,
  members,
  pagination,
}) => {
  const [filter, setFilter] = React.useState({
    page: 1,
    per_page: 10,
  });

  React.useEffect(() => {
    dispatch(actions.fetchCommision(match.params.id));
  }, [dispatch, match.params.id]);

  const [isUploading, setUploadStatus] = React.useState(false);
  const [picturePreview, setPreview] = React.useState('');
  const { handleSubmit, register, errors, setValue } = useForm();
  const [selected, setSelected] = React.useState([]);
  const [deleteConfirm, showDeleteConfirm] = React.useState(false);

  React.useEffect(() => {
    dispatch(actions.fetchCommisionMembers(match.params.id, filter));
  }, [dispatch, match.params.id, filter.page, filter.per_page, filter]);

  const handleUpload = ({ target }) => {
    setUploadStatus(true);
    uploadImage(target.files[0], { folder: 'komisi' })
      .then((res) => {
        setValue('picture', res.secure_url);
        setPreview(res.secure_url);
      })
      .catch(() => {
        dispatch(actions.toggleSnackbar('Gagal menunggah foto!'));
        setValue('');
        setPreview('');
      })
      .finally(() => {
        setUploadStatus(false);
      });
  };

  const handlePageChange = (_, page) => {
    setFilter({ ...filter, page: page + 1 });
  };

  const handlePerPageChange = ({ target }) => {
    setFilter({ page: 1, per_page: target.value });
  };

  const onSubmit = (values) => {
    dispatch(actions.updateCommision(match.params.id, values));
  };

  const handleLeaveCommision = () => {
    dispatch(actions.leaveCommision(match.params.id, selected));
    showDeleteConfirm(false);
  };

  return (
    <React.Fragment>
      <Grid container spacing={2} style={{ marginTop: 8 }}>
        <Grid item md={4}>
          {commision ? (
            <Paper style={{ padding: 16 }}>
              <Typography
                variant="body1"
                style={{ margin: '0 0 16px', fontWeight: 700 }}
              >
                Ubah Data
              </Typography>
              <Divider style={{ marginBottom: 32 }} />
              <form onSubmit={handleSubmit(onSubmit)}>
                <div style={{ marginBottom: 16 }}>
                  <TextField
                    fullWidth
                    label="Nama"
                    name="name"
                    variant="outlined"
                    size="small"
                    inputRef={register({ required: true })}
                    error={errors.hasOwnProperty('name')}
                    defaultValue={commision.name}
                  />
                </div>
                <div style={{ marginBottom: 16 }}>
                  <TextField
                    fullWidth
                    label="Deskripsi"
                    name="description"
                    variant="outlined"
                    size="small"
                    multiline
                    rows={4}
                    inputRef={register({ required: true })}
                    error={errors.hasOwnProperty('description')}
                    defaultValue={commision.description}
                  />
                </div>
                <div style={{ marginBottom: 32 }}>
                  <FormHelperText style={{ marginBottom: 16 }}>
                    Pilih Gambar
                  </FormHelperText>
                  <input
                    accept="image/*"
                    id="input-file"
                    type="file"
                    style={{ display: 'none' }}
                    onChange={handleUpload}
                    onClick={(e) => (e.target.value = null)}
                  />

                  <input
                    name="picture"
                    type="text"
                    style={{ display: 'none' }}
                    value={picturePreview || commision.picture}
                    onChange={() => {}}
                    ref={register({ required: true })}
                  />
                  {errors.hasOwnProperty('picture') ? (
                    <FormHelperText error style={{ marginBottom: 16 }}>
                      Gambar tidak boleh kosong!
                    </FormHelperText>
                  ) : null}
                  {picturePreview || commision.picture ? (
                    <Avatar
                      variant="rounded"
                      style={{ width: 400, height: 'auto', marginTop: 16 }}
                      alt="Uploads"
                      src={getImage(picturePreview || commision.picture)}
                    />
                  ) : null}
                </div>
                <label htmlFor="input-file">
                  <Button
                    variant="contained"
                    component="span"
                    size="small"
                    startIcon={<CloudUploadIcon />}
                  >
                    {isUploading ? 'Sedang mengunggah...' : 'Upload Gambar'}
                  </Button>
                </label>

                <Divider style={{ margin: '32px 0 16px' }} />
                <Button
                  onClick={() => history.push('/d/komisi')}
                  style={{ marginRight: 8 }}
                  variant="outlined"
                >
                  Kembali
                </Button>
                <Button variant="contained" color="primary" type="submit">
                  Simpan
                </Button>
              </form>
            </Paper>
          ) : null}
        </Grid>
        <Grid item md={8}>
          <Paper style={{ padding: 16 }}>
            <Typography
              variant="body1"
              style={{ margin: '0 0 16px', fontWeight: 700 }}
            >
              Anggota
            </Typography>
            <Divider style={{ marginBottom: 32 }} />
            <Table
              dense
              stickyHeader
              data={members}
              columns={memberColumns}
              onRow={{
                onClick: (_, member) =>
                  history.push(`/d/jemaat/${member.user.id}`),
              }}
              getSelectedRow={(rows) => setSelected(rows.map((r) => r.id))}
              header={
                <div style={{ marginBottom: 16 }}>
                  <Button
                    color="secondary"
                    variant="contained"
                    size="small"
                    disabled={selected.length === 0}
                    onClick={() => showDeleteConfirm(true)}
                  >
                    Hapus dari Persekutuan
                  </Button>
                </div>
              }
              pagination={{
                perPageOptions: [10, 25, 50, 100],
                perPage: +filter.per_page || 10,
                page: filter.page ? +filter.page - 1 : 0,
                count: pagination ? pagination.count : 1,
                onChangePage: handlePageChange,
                onChangePerPage: handlePerPageChange,
              }}
            />
          </Paper>
        </Grid>
      </Grid>
      <Dialog open={deleteConfirm} onClose={() => showDeleteConfirm(false)}>
        <DialogTitle id="alert-dialog-title">
          Hapus Jemaat dari Persekutuan
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Anda yakin akan menghapus jemaat dari persekutuan ini?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => showDeleteConfirm(false)} color="primary">
            Batal
          </Button>
          <Button onClick={handleLeaveCommision} color="primary" autoFocus>
            Hapus
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default connect((state) => {
  return {
    loading: state.commision.loading,
    commision: state.commision.detail,
    members: state.commision.members,
    pagination: state.commision.membersPagination,
  };
})(DetailKomisi);
