import React from 'react';
import { Avatar } from '@material-ui/core';
import { getImage } from '../../utils/image';

export const columns = [
  {
    key: 'picture',
    name: 'Gambar',
    render: (src) => <Avatar src={src} />,
  },
  { key: 'name', name: 'Nama' },
  { key: 'description', name: 'Deskripsi' },
];

export const memberColumns = [
  {
    key: 'user.picture',
    name: '#',
    render: (src) => {
      return <Avatar src={getImage(src)} />;
    },
  },
  { key: 'user.name', name: 'Nama' },
  { key: 'user.age', name: 'Umur' },
  { key: 'user.nickname', name: 'Nama Panggilan' },
  { key: 'user.phone', name: 'No Handphone' },
  { key: 'user.address.address', name: 'Alamat' },
  {
    key: 'user.is_baptised',
    name: 'Baptis',
    render: (isBaptised) => (isBaptised ? 'Sudah' : 'Belum'),
  },
];
