import React from 'react';
import { Paper, Typography } from '@material-ui/core';
import s from 'styled-components';

const Card = s(Paper)`
  padding: 16px;
  border: 1px solid #eee;
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
`;

const Statistic = ({ style, title, count, icon }) => {
  return (
    <Card style={style} elevation={0}>
      <div>
        <Typography
          gutterBottom
          variant="body2"
          style={{ color: '#444', fontSize: '12px', fontWeight: 700 }}
        >
          {title}
        </Typography>
        <Typography gutterBottom variant="h4" component="h3">
          {count}
        </Typography>
      </div>
      {icon}
    </Card>
  );
};

export default Statistic;
