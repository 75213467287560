import React from 'react';
import { connect } from 'react-redux';
import GoogleMapReact from 'google-map-react';
import { actions } from '../../reducers';
import getMapOptions from './map-config';

const Map = ({ dispatch, heatmap, loading }) => {
  const markers = null;

  React.useEffect(() => {
    dispatch(actions.fetchStatistics('heatmap'));
  }, [dispatch]);

  return (
    <div
      style={{
        height: 'calc(100vh - 80px)',
        width: 'calc(100vw)',
        margin: '0 -16px',
      }}
    >
      <GoogleMapReact
        bootstrapURLKeys={{
          key: 'AIzaSyC2kj2L7wUjVwDrRCP4k5o3RF5ZU5r6nwI',
        }}
        defaultCenter={[-6.21357514, 106.82269753]}
        defaultZoom={11}
        heatmapLibrary={true}
        heatmap={{
          positions: heatmap,
          options: {
            radius: 30,
          },
        }}
        options={getMapOptions}
      >
        {markers}
      </GoogleMapReact>
    </div>
  );
};

export default connect((state) => {
  return {
    heatmap: state.stats.heatmap,
    loading: state.stats.loading,
  };
})(Map);
