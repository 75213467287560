import api from '../../utils/api';
import * as c from './constants';
import { toggleSnackbar } from '../ui/actions';

const defaultParams = {
  page: 1,
  per_page: 10,
};

export const fetchAttendances = (scheduleID, filter = defaultParams) => {
  return async (dispatch) => {
    try {
      const mapper = {
        per_page: 'limit',
      };

      const query = Object.keys(filter)
        .filter((k) => filter[k])
        .map((k) => `${mapper.hasOwnProperty(k) ? mapper[k] : k}=${filter[k]}`)
        .join('&');

      dispatch({ type: c.ATTENDANCE_LOADING });
      const { data: res } = await api.get(
        `/schedules/${scheduleID}/attendances?${query}`
      );
      dispatch({
        type: c.ATTENDANCES_FULFILLED,
        payload: res.data,
        pagination: res.pagination,
      });
    } catch (error) {
      console.error(error);
      dispatch({ type: c.ATTENDANCES_FULFILLED, payload: [] });
    }
  };
};

export const fetchAttendance = (scheduleID, attendanceID) => {
  return async (dispatch) => {
    try {
      dispatch({ type: c.ATTENDANCE_LOADING });
      const { data: res } = await api.get(
        `/schedules/${scheduleID}/attenndances/${attendanceID}`
      );
      dispatch({ type: c.ATTENDANCE_FULFILLED, payload: res.data });
    } catch (error) {
      console.error(error);
      dispatch({ type: c.ATTENDANCE_FULFILLED, payload: null });
    }
  };
};

export const createAttendance = (scheduleID, data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: c.ATTENDANCE_LOADING });
      const { data: res } = await api.post(
        `/schedules/${scheduleID}/attendances`,
        data
      );
      if (res.success) {
        dispatch(toggleSnackbar('Data berhasil disimpan.'));
      } else {
        dispatch(toggleSnackbar('Gagal menyimpan data.'));
      }

      dispatch(fetchAttendances(scheduleID));
    } catch (error) {
      console.error(error);
      dispatch(toggleSnackbar('Gagal menyimpan data.'));
    }
  };
};

export const deleteAttendance = (
  scheduleID,
  attendanceIDs,
  isServant = false
) => {
  return async (dispatch) => {
    try {
      await api.delete(`/schedules/${scheduleID}/attendances`, {
        data: attendanceIDs,
      });

      dispatch(fetchAttendances(scheduleID));
      dispatch(toggleSnackbar('Data berhasil dihapus.'));
    } catch (error) {
      console.error(error);
      dispatch(toggleSnackbar('Gagal menghapus data.'));
    }
  };
};

export const markAsAttend = (scheduleID, userSK) => {
  return async (dispatch) => {
    try {
      await api.post(`/schedules/${scheduleID}/onsite-attendances`, {
        user_sk: userSK,
      });

      dispatch(fetchAttendances(scheduleID));
      dispatch(toggleSnackbar('Data berhasil diubah.'));
    } catch (error) {
      console.error(error);
      dispatch(toggleSnackbar('Gagal mengubah data.'));
    }
  };
};
