import * as c from './constants';

export const initialState = {
  loading: false,
  data: [],
  detail: null,
  pagination: {
    count: 0,
    page: 1,
    limit: 10,
    has_next: false,
  },
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case c.USER_LOADING:
      return { ...state, loading: true, data: [], detail: null };
    case c.USERS_FULFILLED:
      return {
        ...state,
        loading: false,
        data: action.payload,
        pagination: action.pagination,
      };
    case c.USER_FULFILLED:
      return { ...state, loading: false, detail: action.payload };
    default:
      return state;
  }
};

export default userReducer;
