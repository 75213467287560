import React from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import {
  Avatar,
  Dialog,
  Divider,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  FormHelperText,
} from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import uploadImage from '../../../utils/uploadImage';
import { actions } from '../../../reducers';
import { getImage } from '../../../utils/image';

const AddCommision = ({ dispatch, open, handleClose }) => {
  const [isUploading, setUploadStatus] = React.useState(false);
  const [picturePreview, setPreview] = React.useState('');
  const { handleSubmit, register, errors, setValue } = useForm();
  const handleUpload = ({ target }) => {
    setUploadStatus(true);
    uploadImage(target.files[0], { folder: 'komisi' })
      .then((res) => {
        setValue('picture', res.secure_url);
        setPreview(res.secure_url);
      })
      .catch(() => {
        dispatch(actions.toggleSnackbar('Gagal menunggah foto!'));
        setValue('');
        setPreview('');
      })
      .finally(() => {
        setUploadStatus(false);
      });
  };

  const onSubmit = (values) => {
    dispatch(actions.createCommision(values));
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle id="form-dialog-title">Buat Baru</DialogTitle>
      <Divider style={{ marginBottom: 16 }} />
      <DialogContent style={{ width: 500 }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div style={{ marginBottom: 16 }}>
            <TextField
              fullWidth
              label="Nama"
              name="name"
              size="small"
              variant="outlined"
              inputRef={register({ required: true })}
              error={errors.hasOwnProperty('name')}
            />
          </div>
          <div style={{ marginBottom: 16 }}>
            <TextField
              fullWidth
              label="Deskripsi"
              name="description"
              size="small"
              variant="outlined"
              multiline
              rows={4}
              inputRef={register({ required: true })}
              error={errors.hasOwnProperty('description')}
            />
          </div>
          <div style={{ marginBottom: 16 }}>
            <FormHelperText style={{ marginBottom: 16 }}>
              Pilih Gambar
            </FormHelperText>
            <input
              accept="image/*"
              id="input-file"
              type="file"
              style={{ display: 'none' }}
              onChange={handleUpload}
              onClick={(e) => (e.target.value = null)}
            />

            <input
              name="picture"
              type="text"
              style={{ display: 'none' }}
              value={picturePreview}
              onChange={() => {}}
              ref={register({ required: true })}
            />
            {errors.hasOwnProperty('picture') ? (
              <FormHelperText error style={{ marginBottom: 16 }}>
                Gambar tidak boleh kosong!
              </FormHelperText>
            ) : null}
            {picturePreview ? (
              <Avatar
                variant="square"
                style={{ width: '100%', height: 'auto', margin: '16px 0' }}
                alt="Uploads"
                src={getImage(picturePreview)}
              />
            ) : null}

            <label htmlFor="input-file">
              <Button
                variant="contained"
                component="span"
                size="small"
                startIcon={<CloudUploadIcon />}
              >
                {isUploading ? 'Sedang mengunggah...' : 'Upload Gambar'}
              </Button>
            </label>
          </div>
          <Divider />
          <div style={{ margin: '16px 0' }}>
            <Button variant="contained" color="primary" type="submit">
              Simpan
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default connect()(AddCommision);
