import React from 'react';
import format from 'date-fns/format';
import { Avatar } from '@material-ui/core';

import { getImage } from '../../utils/image';

export const columns = [
  { key: 'title', name: 'Judul' },
  { key: 'description', name: 'Deskripsi' },
  {
    key: 'is_published',
    name: 'Tampilkan',
    render: (text) => (text ? 'Ya' : 'Tidak'),
  },
  {
    key: 'is_available_to_vote',
    name: 'Ijinkan Submit',
    render: (text) => (text ? 'Ya' : 'Tidak'),
  },
  {
    key: 'created_at',
    name: 'Tanggal',
    render: (date) => format(new Date(date), 'dd MMM yyyy HH:mm'),
  },
];

export const pollingColumns = [
  { key: 'title', name: 'Judul' },
  { key: 'title', name: 'Judul (dalam Mandarin)' },
  { key: 'description', name: 'Deskripsi' },
  {
    key: 'min_selection',
    name: 'Min. Pilihan',
  },
  {
    key: 'max_selection',
    name: 'Max. Pilihan',
  },
];

export const optionColumns = [
  {
    key: 'picture',
    name: 'Foto',
    render: (src) => <Avatar src={getImage(src)} />,
  },
  { key: 'name', name: 'Nama Pilihan' },
  { key: 'name_zh', name: 'Nama Pilihan (dalam Mandarin)' },
  { key: 'description', name: 'Deskripsi' },
];

const renderVolunteer = (data) => {
  return data.volunteer ? (
    <div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Avatar src={getImage(data.volunteer.picture)} />
        <div style={{ marginLeft: 8 }}>
          <strong>{data.volunteer.name}</strong>
          <p style={{ margin: 0 }}>
            Bukti kuasa:{' '}
            {data.evidence ? (
              <a href={data.evidence} target="_blank" rel="noopener noreferrer">
                Lihat bukti kuasa
              </a>
            ) : (
              'Belum ada'
            )}
          </p>
        </div>
      </div>
    </div>
  ) : (
    'Pemilih mandiri'
  );
};

export const voterColumns = [
  {
    key: 'user.picture',
    name: 'Foto',
    render: (src) => <Avatar src={getImage(src)} />,
  },
  {
    name: 'Nama Pemilih',
    render: (voter) => {
      return (
        <>
          <div>{voter.user.name}</div>
          {voter.votes.length ? (
            <div
              style={{
                color: '#3f51b5',
                fontSize: 12,
              }}
            >
              Lihat pilihan partisipan
            </div>
          ) : null}
        </>
      );
    },
  },
  {
    name: 'Diwakilkan oleh',
    render: renderVolunteer,
  },
  {
    key: 'voted_at',
    name: 'Status Pemilihan',
    render: (voted_at) =>
      voted_at
        ? format(new Date(voted_at), 'dd MMM yyyy HH:mm')
        : 'Belum memilih',
  },
];

export const voteColumns = [
  {
    key: 'voter.user.picture',
    name: 'Foto',
    render: (src) => {
      return <Avatar src={getImage(src)} />;
    },
  },
  { key: 'voter.user.name', name: 'Nama Pemilih' },
  {
    key: 'created_at',
    name: 'Tanggal/Jam',
    render: (created_at) => format(new Date(created_at), 'dd MMM yyyy HH:mm'),
  },
  {
    key: 'voter',
    name: 'Diwakilkan Oleh',
    render: renderVolunteer,
  },
];

const chartColors = [
  '#F44336',
  '#8BC34A',
  '#03A9F4',
  '#FF5722',
  '#E91E63',
  '#673AB7',
  '#CDDC39',
  '#9C27B0',
  '#311B92',
  '#3F51B5',
  '#2196F3',
  '#00BCD4',
  '#009688',
  '#4CAF50',
  '#FFEB3B',
  '#FFC107',
  '#FF9800',
  '#795548',
  '#757575',
];

export const createPollChart = (stats) => {
  if (!stats) {
    return { labels: [], datasets: [] };
  }

  return {
    labels: stats.map((s) => s.name),
    datasets: [
      {
        data: stats.map((s) => s.total),
        backgroundColor: chartColors,
      },
    ],
  };
};
