import * as c from './constants';

export const initialState = {
  loading: false,
  data: [],
};

const userRelationshipReducer = (state = initialState, action) => {
  switch (action.type) {
    case c.USER_RELATIONSHIP_LOADING:
      return { ...state, loading: true, data: [], detail: null };
    case c.USER_RELATIONSHIPS_FULFILLED:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    default:
      return state;
  }
};

export default userRelationshipReducer;
