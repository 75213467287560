import React from 'react';
import { Avatar } from '@material-ui/core';
import format from 'date-fns/format';

import { getImage } from '../../utils/image';

export const membershipMapper = {
  PERMANENT: 'Jemaat Tetap',
  TEMPORARY: 'Simpatisan',
};

export const columns = [
  {
    key: 'picture',
    name: 'Foto',
    render: (src) => <Avatar src={getImage(src)} />,
  },
  {
    name: 'Nama',
    render: (record) => {
      const mandarinName = record.mandarin_name
        ? ` (${record.mandarin_name})`
        : '';
      return record.name + mandarinName;
    },
  },
  { key: 'nickname', name: 'Panggilan' },
  {
    key: 'date_of_birth',
    name: 'Tanggal Lahir',
    render: (text) => format(new Date(text), 'dd MMM yyyy'),
  },
  { key: 'age', name: 'Umur', render: (age) => `${age} tahun` },
  {
    key: 'membership',
    name: 'Keanggotaan',
    render: (value) => membershipMapper[value],
  },
];
