import api from '../../utils/api';
import * as c from './constants';
import { toggleSnackbar } from '../ui/actions';

const defaultParams = {
  page: 1,
  per_page: 10,
  name: '',
};

export const fetchPollNamespaces = (filter = defaultParams) => {
  return async (dispatch) => {
    try {
      const mapper = {
        per_page: 'limit',
      };

      const query = Object.keys(filter)
        .filter((k) => filter[k])
        .map((k) => `${mapper.hasOwnProperty(k) ? mapper[k] : k}=${filter[k]}`)
        .join('&');

      dispatch({ type: c.POLL_NAMESPACE_LOADING });
      const { data: res } = await api.get(`/poll-namespaces/search?${query}`);
      dispatch({
        type: c.POLL_NAMESPACES_FULFILLED,
        payload: res.data,
        pagination: res.pagination,
      });
    } catch (error) {
      console.error(error);
      dispatch({ type: c.POLL_NAMESPACES_FULFILLED, payload: [] });
    }
  };
};

export const createPollNamespace = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: c.POLL_NAMESPACE_LOADING });
      await api.post('/poll-namespaces/', data);
      dispatch(fetchPollNamespaces());
      dispatch(toggleSnackbar('Data berhasil disimpan.'));
    } catch (error) {
      console.error(error);
      dispatch(toggleSnackbar('Gagal menyimpan data.'));
    }
  };
};

export const fetchPollNamespace = (namespaceID) => {
  return async (dispatch) => {
    try {
      dispatch({ type: c.POLL_NAMESPACE_LOADING });
      const { data: res } = await api.get(`/poll-namespaces/${namespaceID}`);
      dispatch({ type: c.POLL_NAMESPACE_FULFILLED, payload: res.data });
    } catch (error) {
      console.error(error);
      dispatch({ type: c.POLL_NAMESPACE_FULFILLED, payload: null });
    }
  };
};

export const fetchPollNamespaceStats = (namespaceID) => {
  return async (dispatch) => {
    try {
      dispatch({ type: c.POLL_NAMESPACE_LOADING });
      const { data: res } = await api.get(
        `/poll-namespaces/${namespaceID}/stats`,
      );
      dispatch({ type: c.POLL_NAMESPACE_STATS_FULFILLED, payload: res.data });
    } catch (error) {
      console.error(error);
      dispatch({ type: c.POLL_NAMESPACE_STATS_FULFILLED, payload: [] });
    }
  };
};

export const fetchVoterStats = (namespaceID) => {
  return async (dispatch) => {
    try {
      const { data: res } = await api.get(
        `/poll-namespaces/${namespaceID}/voters-stats`,
      );
      dispatch({ type: c.POLL_VOTER_STATS_FULFILLED, payload: res.data });
    } catch (error) {
      console.error(error);
      dispatch({ type: c.POLL_VOTER_STATS_FULFILLED, payload: [] });
    }
  };
};

export const updatePollNamespace = (namespaceID, data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: c.POLL_NAMESPACE_LOADING });
      await api.put(`/poll-namespaces/${namespaceID}`, data);
      dispatch(fetchPollNamespace(namespaceID));
      dispatch(toggleSnackbar('Data berhasil disimpan.'));
    } catch (error) {
      console.error(error);
      dispatch(toggleSnackbar('Gagal menyimpan data.'));
    }
  };
};

export const deleteNamespace = (namespaceID, history) => {
  return async (dispatch) => {
    try {
      await api.delete(`/poll-namespaces/`, {
        data: [namespaceID],
      });

      dispatch(toggleSnackbar('Data berhasil dihapus.'));
      history.push('/d/poll-namespace');
    } catch (error) {
      console.error(error);
      dispatch(toggleSnackbar('Gagal menghapus data.'));
    }
  };
};
