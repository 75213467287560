import format from 'date-fns/format';
export const columns = [
  { key: 'name', name: 'Nama' },
  { key: 'description', name: 'Deskripsi' },
  { key: 'church.name', name: 'Gereja' },
];

export const scheduleColumns = [
  { key: 'worship.name', name: 'Ibadah' },
  { key: 'description', name: 'Deskripsi' },
  {
    key: 'started_at',
    name: 'Tanggal Jam',
    render: (val) => {
      const dateTime = new Date(val);
      return format(dateTime, 'dd MMM yyyy HH:mm');
    },
  },
];
