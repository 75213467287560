import React from 'react';
import { connect } from 'react-redux';
import { useForm } from 'react-hook-form';
import {
  Grid,
  Typography,
  Divider,
  Paper,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';

import Options from './Options';
import { actions } from '../../../reducers';

const EditPoll = ({ dispatch, match, history, poll }) => {
  const { handleSubmit, register, errors } = useForm();
  const [deleteConfirm, showDeleteConfirm] = React.useState(false);

  React.useEffect(() => {
    dispatch(actions.fetchPoll(match.params.id, match.params.poll_id));
  }, [dispatch, match.params.id, match.params.poll_id]);

  const onSubmit = (values) => {
    dispatch(actions.updatePoll(match.params.id, match.params.poll_id, values));
  };

  return (
    <React.Fragment>
      <Grid container spacing={2} style={{ marginTop: 8 }}>
        <Grid item md={4}>
          {poll ? (
            <Paper style={{ padding: 16 }}>
              <Typography
                variant="body1"
                style={{ margin: '0 0 16px', fontWeight: 700 }}
              >
                Ubah Data
              </Typography>
              <Divider style={{ marginBottom: 32 }} />
              <form onSubmit={handleSubmit(onSubmit)}>
                <div style={{ marginBottom: 16 }}>
                  <TextField
                    fullWidth
                    label="Judul"
                    name="title"
                    size="small"
                    variant="outlined"
                    inputRef={register({ required: true })}
                    error={errors.hasOwnProperty('title')}
                    defaultValue={poll.title}
                  />
                </div>
                <div style={{ marginBottom: 16 }}>
                  <TextField
                    fullWidth
                    label="Judul (dalam Mandarin)"
                    name="title_zh"
                    size="small"
                    variant="outlined"
                    inputRef={register}
                    error={errors.hasOwnProperty('title_zh')}
                    defaultValue={poll.title_zh}
                  />
                </div>
                <div style={{ marginBottom: 16 }}>
                  <TextField
                    fullWidth
                    label="Deskripsi"
                    name="description"
                    size="small"
                    variant="outlined"
                    multiline
                    rows={4}
                    inputRef={register}
                    error={errors.hasOwnProperty('description')}
                    defaultValue={poll.description}
                  />
                </div>
                <div style={{ marginBottom: 16 }}>
                  <TextField
                    fullWidth
                    label="Deskripsi (dalam Mandarin)"
                    name="description_zh"
                    size="small"
                    variant="outlined"
                    multiline
                    rows={4}
                    inputRef={register}
                    error={errors.hasOwnProperty('description_zh')}
                    defaultValue={poll.description_zh}
                  />
                </div>
                <div style={{ marginBottom: 16 }}>
                  <TextField
                    fullWidth
                    label="Min. Pilihan"
                    name="min_selection"
                    size="small"
                    type="number"
                    variant="outlined"
                    inputRef={register({ required: true })}
                    error={errors.hasOwnProperty('min_selection')}
                    defaultValue={poll.min_selection}
                  />
                </div>
                <div style={{ marginBottom: 16 }}>
                  <TextField
                    fullWidth
                    label="Max. Pilihan"
                    name="max_selection"
                    size="small"
                    type="number"
                    variant="outlined"
                    inputRef={register({ required: true })}
                    error={errors.hasOwnProperty('max_selection')}
                    defaultValue={poll.max_selection}
                  />
                </div>
                <Divider />
                <div style={{ margin: '16px 0' }}>
                  <Button variant="contained" color="primary" type="submit">
                    Simpan
                  </Button>
                </div>
              </form>
            </Paper>
          ) : null}
          <Paper style={{ padding: 16, marginTop: 16 }}>
            <Typography
              variant="body1"
              style={{ margin: '0 0 16px', fontWeight: 700 }}
            >
              Hapus
            </Typography>
            <Divider />
            <div style={{ margin: '8px 0' }}>
              <Typography variant="caption">
                Menghapus kategori polling akan menghapus peserta dan pilihan di
                dalam polling.
              </Typography>
            </div>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                showDeleteConfirm(true);
              }}
            >
              Hapus Kategori
            </Button>
            <Dialog
              open={deleteConfirm}
              onClose={() => showDeleteConfirm(false)}
            >
              <DialogTitle id="alert-dialog-title">Hapus Kategori</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Anda yakin akan menghapus kategori ini?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => showDeleteConfirm(false)}
                  color="primary"
                >
                  Batal
                </Button>
                <Button
                  onClick={() => {
                    dispatch(
                      actions.deletePoll(
                        match.params.id,
                        match.params.poll_id,
                        history,
                      ),
                    );
                  }}
                  color="primary"
                  autoFocus
                >
                  Hapus
                </Button>
              </DialogActions>
            </Dialog>
          </Paper>
        </Grid>
        <Grid item md={8}>
          <Options
            namespaceID={match.params.id}
            pollID={match.params.poll_id}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default connect((state) => {
  return {
    loading: state.poll.loading,
    poll: state.poll.detail,
  };
})(EditPoll);
