import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { connect } from 'react-redux';
import {
  Dialog,
  Divider,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import { DateTimePicker } from '@material-ui/pickers';

import { actions } from '../../../reducers';

const AddSchedule = ({ dispatch, open, handleClose, worships }) => {
  const { handleSubmit, register, errors, control, watch } = useForm({
    defaultValues: { worship_type: 'RSVP' },
  });
  const worshipType = watch('worship_type');

  React.useEffect(() => {
    dispatch(actions.fetchWorships());
  }, []); // eslint-disable-line

  const onSubmit = (values) => {
    values = {
      ...values,
      max_capacity: values.worship_type === 'RSVP' ? values.max_capacity : 0,
    };
    dispatch(actions.createSchedule(values));
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle id="form-dialog-title">Buat Baru</DialogTitle>
      <Divider style={{ marginBottom: 16 }} />
      <DialogContent style={{ width: 500 }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div style={{ marginBottom: 16 }}>
            <FormControl
              variant="outlined"
              margin="normal"
              style={{ width: '100%' }}
            >
              <InputLabel>Kebaktian</InputLabel>
              <Controller
                as={
                  <Select variant="outlined" label="Kebaktian">
                    {worships.map((w) => (
                      <MenuItem key={w.id} value={w.id}>
                        {w.name}
                      </MenuItem>
                    ))}
                  </Select>
                }
                name="worship_id"
                control={control}
                onChange={([selected]) => {
                  return selected.target.value;
                }}
              />
            </FormControl>
          </div>
          <div style={{ marginBottom: 16 }}>
            <TextField
              fullWidth
              label="Deskripsi"
              name="description"
              size="small"
              variant="outlined"
              multiline
              rows={4}
              inputRef={register({ required: true })}
              error={errors.hasOwnProperty('description')}
            />
          </div>
          <div style={{ marginBottom: 16 }}>
            <InputLabel shrink>Tipe Absensi</InputLabel>
            <Controller
              as={
                <Select fullWidth variant="outlined">
                  <MenuItem value="RSVP">Reservasi</MenuItem>
                  <MenuItem value="ONSITE">Onsite</MenuItem>
                </Select>
              }
              name="worship_type"
              control={control}
              onChange={([selected]) => {
                return selected.target.value;
              }}
              defaultValue="RSVP"
            />
          </div>
          {worshipType === 'RSVP' ? (
            <div style={{ marginBottom: 16 }}>
              <TextField
                fullWidth
                label="Kapasitas"
                name="max_capacity"
                size="small"
                type="number"
                variant="outlined"
                inputRef={register({ required: true })}
                error={errors.hasOwnProperty('max_capacity')}
                defaultValue={50}
              />
            </div>
          ) : null}
          <div style={{ marginBottom: 16 }}>
            <Controller
              as={
                <DateTimePicker
                  inputVariant="outlined"
                  style={{ width: '100%' }}
                  ampm={false}
                  margin="normal"
                  label="Jadwal Kebaktian"
                  minDate={new Date()}
                  format="dd MMM yyyy HH:mm"
                />
              }
              name="started_at"
              control={control}
              defaultValue={new Date()}
            />
          </div>
          <Divider />
          <div style={{ margin: '16px 0' }}>
            <Button variant="contained" color="primary" type="submit">
              Simpan
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default connect((state) => {
  return {
    worships: state.worship.data,
  };
})(AddSchedule);
