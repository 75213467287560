import React from 'react';
import { connect } from 'react-redux';
import { useForm } from 'react-hook-form';
import {
  Grid,
  Typography,
  Divider,
  Paper,
  TextField,
  Button,
} from '@material-ui/core';

import { Table } from '../../../components';
import { actions } from '../../../reducers';
import { scheduleColumns } from '../utils';

const DetailKomisi = ({
  dispatch,
  match,
  loading,
  history,
  worship,
  schedules,
  pagination,
}) => {
  const [filter, setFilter] = React.useState({
    page: 1,
    per_page: 10,
  });

  React.useEffect(() => {
    dispatch(actions.fetchWorship(match.params.id));
  }, [dispatch, match.params.id]);

  React.useEffect(() => {
    dispatch(actions.fetchScheduleByWorshipID(match.params.id, filter));
  }, [dispatch, match.params.id, filter.page, filter.per_page, filter]);

  const { handleSubmit, register, errors } = useForm();

  const handlePageChange = (_, page) => {
    setFilter({ ...filter, page: page + 1 });
  };

  const handlePerPageChange = ({ target }) => {
    setFilter({ page: 1, per_page: target.value });
  };

  const onSubmit = (values) => {
    dispatch(actions.updateWorship(match.params.id, values));
  };

  return (
    <React.Fragment>
      <Grid container spacing={2} style={{ marginTop: 8 }}>
        <Grid item md={4}>
          {worship ? (
            <Paper style={{ padding: 16 }}>
              <Typography
                variant="body1"
                style={{ margin: '0 0 16px', fontWeight: 700 }}
              >
                Ubah Data
              </Typography>
              <Divider style={{ marginBottom: 32 }} />
              <form onSubmit={handleSubmit(onSubmit)}>
                <div style={{ marginBottom: 16 }}>
                  <TextField
                    fullWidth
                    label="Nama"
                    name="name"
                    variant="outlined"
                    size="small"
                    inputRef={register({ required: true })}
                    error={errors.hasOwnProperty('name')}
                    defaultValue={worship.name}
                  />
                </div>
                <div style={{ marginBottom: 16 }}>
                  <TextField
                    fullWidth
                    label="Deskripsi"
                    name="description"
                    variant="outlined"
                    size="small"
                    inputRef={register({ required: true })}
                    error={errors.hasOwnProperty('description')}
                    defaultValue={worship.description}
                  />
                </div>

                <Divider style={{ margin: '32px 0 16px' }} />
                <Button
                  onClick={() => history.push('/d/kebaktian')}
                  style={{ marginRight: 8 }}
                  variant="outlined"
                >
                  Kembali
                </Button>
                <Button variant="contained" color="primary" type="submit">
                  Simpan
                </Button>
              </form>
            </Paper>
          ) : null}
        </Grid>
        <Grid item md={8}>
          <Paper style={{ padding: 16 }}>
            <Typography
              variant="body1"
              style={{ margin: '0 0 16px', fontWeight: 700 }}
            >
              Jadwal Ibadah
            </Typography>
            <Divider style={{ marginBottom: 32 }} />
            <Table
              dense
              stickyHeader
              data={schedules}
              columns={scheduleColumns}
              onRow={{
                onClick: (_, jadwal) => history.push(`/d/jadwal/${jadwal.id}`),
              }}
              pagination={{
                perPageOptions: [10, 25, 50, 100],
                perPage: +filter.per_page || 10,
                page: filter.page ? +filter.page - 1 : 0,
                count: pagination ? pagination.count : 1,
                onChangePage: handlePageChange,
                onChangePerPage: handlePerPageChange,
              }}
            />
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default connect((state) => {
  return {
    loading: state.worship.loading,
    worship: state.worship.detail,
    schedules: state.worship.schedules,
    pagination: state.worship.schedulesPagination,
  };
})(DetailKomisi);
