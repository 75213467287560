import React from 'react';
import s from 'styled-components';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import Typography from '@material-ui/core/Typography';

const Outer = s.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    flex-flow: column wrap;
`;

const Empty = props => {
  return (
    <Outer>
      <ReportProblemIcon style={{ width: 50, height: 50 }} />
      <Typography variant="h6">{props.content}</Typography>
    </Outer>
  );
};

Empty.defaultProps = {
  content: 'Data belum tersedia.',
};

export default Empty;
