import api from '../../utils/api';
import * as c from './constants';
import { toggleSnackbar } from '../ui/actions';

const defaultParams = {
  page: 1,
  per_page: 10,
  name: '',
};

export const fetchCommisions = (filter = defaultParams) => {
  return async (dispatch) => {
    try {
      const mapper = {
        per_page: 'limit',
      };

      const query = Object.keys(filter)
        .filter((k) => filter[k])
        .map((k) => `${mapper.hasOwnProperty(k) ? mapper[k] : k}=${filter[k]}`)
        .join('&');

      dispatch({ type: c.COMMISION_LOADING });
      const { data: res } = await api.get(`/commisions/?${query}`);
      dispatch({
        type: c.COMMISIONS_FULFILLED,
        payload: res.data,
        pagination: res.pagination,
      });
    } catch (error) {
      console.error(error);
      dispatch({ type: c.COMMISIONS_FULFILLED, payload: [] });
    }
  };
};

export const fetchCommision = (commisionID) => {
  return async (dispatch) => {
    try {
      dispatch({ type: c.COMMISION_LOADING });
      const { data: res } = await api.get(`/commisions/${commisionID}`);
      dispatch({ type: c.COMMISION_FULFILLED, payload: res.data });
    } catch (error) {
      console.error(error);
      dispatch({ type: c.COMMISION_FULFILLED, payload: null });
    }
  };
};

export const createCommision = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: c.COMMISION_LOADING });
      await api.post('/commisions/', data);
      dispatch(fetchCommisions());
      dispatch(toggleSnackbar('Data berhasil disimpan.'));
    } catch (error) {
      console.error(error);
      dispatch(toggleSnackbar('Gagal menyimpan data.'));
    }
  };
};

export const updateCommision = (commisionID, data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: c.COMMISION_LOADING });
      await api.put(`/commisions/${commisionID}`, data);
      dispatch(fetchCommision(commisionID));
      dispatch(toggleSnackbar('Data berhasil disimpan.'));
    } catch (error) {
      console.error(error);
      dispatch(toggleSnackbar('Gagal menyimpan data.'));
    }
  };
};

const defaultMemberParams = {
  page: 1,
  per_page: 10,
};
export const fetchCommisionMembers = (
  commisionID,
  filter = defaultMemberParams,
) => {
  return async (dispatch) => {
    try {
      const mapper = {
        per_page: 'limit',
      };
      const query = Object.keys(filter)
        .filter((k) => filter[k])
        .map((k) => `${mapper.hasOwnProperty(k) ? mapper[k] : k}=${filter[k]}`)
        .join('&');

      const { data: res } = await api.get(
        `/commisions/${commisionID}/members?${query}`,
      );
      dispatch({
        type: c.COMMISION_MEMBER_FULFILLED,
        payload: res.data,
        pagination: res.pagination,
      });
    } catch (error) {
      console.error(error);
      dispatch({ type: c.COMMISION_MEMBER_FULFILLED, payload: [] });
    }
  };
};

export const leaveCommision = (commisionID, userCommisionID) => {
  return async (dispatch) => {
    try {
      await api.delete(`/commision-members/`, {
        data: userCommisionID,
      });

      dispatch(fetchCommisionMembers(commisionID));
      dispatch(toggleSnackbar('Data berhasil dihapus.'));
    } catch (error) {
      console.error(error);
      dispatch(toggleSnackbar('Gagal mengubah data.'));
    }
  };
};
