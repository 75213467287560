import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  TablePagination,
  Paper,
  Typography,
  Checkbox,
} from '@material-ui/core';
import get from 'lodash/get';

const CustomTable = ({
  header,
  columns,
  data,
  onRow,
  pagination,
  stickyHeader,
  dense,
  title,
  getSelectedRow,
}) => {
  const [selected, setSelected] = React.useState([]);

  React.useEffect(() => {
    if (typeof getSelectedRow === 'function') {
      getSelectedRow(selected);
    }
    // eslint-disable-next-line
  }, [selected]);

  return (
    <React.Fragment>
      {header}

      <TableContainer component={Paper}>
        {title ? (
          <Typography variant="body1" style={{ padding: 16, fontWeight: 700 }}>
            {title}
          </Typography>
        ) : null}
        <Table
          stickyHeader={stickyHeader}
          aria-label="simple table"
          size={dense ? 'small' : 'medium'}
        >
          <TableHead>
            <TableRow>
              {typeof getSelectedRow === 'function' ? (
                <TableCell
                  padding="checkbox"
                  style={{
                    padding: dense ? '16px' : null,
                  }}
                >
                  <Checkbox
                    size="small"
                    checked={
                      selected.length === data.length && selected.length > 0
                    }
                    onClick={() =>
                      selected.length !== data.length
                        ? setSelected(data.map((r) => ({ id: +r.id, data: r })))
                        : setSelected([])
                    }
                  />
                </TableCell>
              ) : null}
              {columns.map((col, idx) => (
                <TableCell
                  key={idx}
                  align={col.hasOwnProperty('align') ? col.align : 'left'}
                  style={{
                    fontWeight: 700,
                    padding: dense ? '16px' : null,
                  }}
                >
                  {col.name}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length === 0 ? (
              <TableRow>
                <TableCell
                  align="center"
                  colSpan={
                    typeof getSelectedRow === 'function'
                      ? columns.length + 1
                      : columns.length
                  }
                >
                  <Typography variant="body1" color="textSecondary">
                    Tidak ada data
                  </Typography>
                </TableCell>
              </TableRow>
            ) : null}
            {data.map((row, rowIdx) => {
              return (
                <TableRow hover key={rowIdx}>
                  {typeof getSelectedRow === 'function' ? (
                    <TableCell
                      padding="checkbox"
                      style={{
                        padding: dense ? '16px' : null,
                      }}
                    >
                      <Checkbox
                        size="small"
                        checked={
                          selected.filter((s) => row.id === s.id).length > 0
                        }
                        onClick={() =>
                          setSelected(
                            selected.find((s) => s.id === +row.id)
                              ? selected.filter((s) => s.id !== +row.id)
                              : selected.concat([{ id: +row.id, data: row }]),
                          )
                        }
                      />
                    </TableCell>
                  ) : null}
                  {columns.map((col, colIdx) => {
                    const value = get(row, col.key, row);
                    return (
                      <TableCell
                        key={colIdx}
                        onClick={(event) => onRow.onClick(event, row)}
                        style={{ cursor: 'pointer' }}
                      >
                        {col.hasOwnProperty('render') &&
                        typeof col.render === 'function'
                          ? col.render(value)
                          : typeof value === 'object'
                          ? ''
                          : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
          {pagination ? (
            <TableFooter>
              <TableRow>
                <TablePagination
                  count={pagination.count}
                  rowsPerPage={pagination.perPage}
                  page={pagination.page}
                  SelectProps={{
                    inputProps: { 'aria-label': 'rows per page' },
                    native: true,
                  }}
                  rowsPerPageOptions={pagination.perPageOptions}
                  onChangePage={pagination.onChangePage}
                  onChangeRowsPerPage={pagination.onChangePerPage}
                />
              </TableRow>
            </TableFooter>
          ) : null}
        </Table>
      </TableContainer>
    </React.Fragment>
  );
};

CustomTable.defaultProps = {
  stickyHeader: false,
  columns: [],
  dense: false,
  data: [],
  onRow: {
    onClick: () => {},
  },
};

export default CustomTable;
