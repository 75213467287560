import React from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import {
  Dialog,
  Divider,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
} from '@material-ui/core';

import { actions } from '../../../reducers';

const AddWorship = ({ dispatch, open, handleClose }) => {
  const { handleSubmit, register, errors } = useForm();

  const onSubmit = (values) => {
    dispatch(actions.createWorship(values));
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle id="form-dialog-title">Buat Baru</DialogTitle>
      <Divider style={{ marginBottom: 16 }} />
      <DialogContent style={{ width: 500 }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div style={{ marginBottom: 16 }}>
            <TextField
              fullWidth
              label="Nama"
              name="name"
              size="small"
              variant="outlined"
              inputRef={register({ required: true })}
              error={errors.hasOwnProperty('name')}
            />
          </div>
          <div style={{ marginBottom: 16 }}>
            <TextField
              fullWidth
              label="Deskripsi"
              name="description"
              size="small"
              variant="outlined"
              inputRef={register({ required: true })}
              error={errors.hasOwnProperty('description')}
            />
          </div>
          <Divider />
          <div style={{ margin: '16px 0' }}>
            <Button variant="contained" color="primary" type="submit">
              Simpan
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default connect()(AddWorship);
