import React from 'react';
import debounce from 'lodash/debounce';
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import api from '../../utils/api';

const OwnerDialog = ({ open, onClose, onSelect, currentAuth }) => {
  const [ownerID, setOwnerID] = React.useState(null);
  const [options, setOptions] = React.useState([]);

  const fetchData = debounce((name) => {
    api
      .get(`/auth/?name=${name}`)
      .then(({ data: res }) => {
        setOptions(res.data);
      })
      .catch(() => setOptions([]));
  }, 400);

  const handleSearchUser = ({ target }) => {
    if (target.value) {
      fetchData(target.value);
    } else {
      setOptions([]);
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => onClose()}
        aria-labelledby="change-owner-dialog-title"
      >
        <DialogTitle id="change-owner-dialog-title">
          Ganti Pemilik Data
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Hanya lakukan pemindahan akun jika diminta oleh yang bersangkutan.
          </DialogContentText>
          <FormControl fullWidth style={{ marginBottom: 16 }}>
            <Autocomplete
              onChange={(_, selected) => setOwnerID(selected.id)}
              getOptionSelected={(option, value) => option.name === value.name}
              getOptionLabel={(option) => option.name}
              options={options.filter((o) => o.id !== +currentAuth)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Ketik nama pemilik..."
                  fullWidth
                  onChange={handleSearchUser}
                  InputProps={params.InputProps}
                />
              )}
            />
          </FormControl>
        </DialogContent>
        <DialogActions style={{ padding: '8px 16px' }}>
          <Button onClick={() => onClose()}>Batal</Button>
          <Button
            disabled={!ownerID}
            onClick={() => {
              onSelect(ownerID);
            }}
            color="primary"
          >
            Simpan
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default OwnerDialog;
