import api from '../../utils/api';
import * as c from './constants';
import { toggleSnackbar } from '../ui/actions';

export const fetchPolls = (namespaceID) => {
  return async (dispatch) => {
    try {
      dispatch({ type: c.POLL_LOADING });
      const { data: res } = await api.get(
        `/poll-namespaces/${namespaceID}/polls`,
      );
      dispatch({
        type: c.POLLS_FULFILLED,
        payload: res.data,
      });
    } catch (error) {
      console.error(error);
      dispatch({ type: c.POLLS_FULFILLED, payload: [] });
    }
  };
};

export const createPoll = (namespaceID, data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: c.POLL_LOADING });
      await api.post(`/poll-namespaces/${namespaceID}/polls`, data);
      dispatch(fetchPolls(namespaceID));
      dispatch(toggleSnackbar('Data berhasil disimpan.'));
    } catch (error) {
      console.error(error);
      dispatch(toggleSnackbar('Gagal menyimpan data.'));
    }
  };
};

export const fetchPoll = (namespaceID, pollID) => {
  return async (dispatch) => {
    try {
      dispatch({ type: c.POLL_LOADING });
      const { data: res } = await api.get(
        `/poll-namespaces/${namespaceID}/polls/${pollID}`,
      );
      dispatch({ type: c.POLL_FULFILLED, payload: res.data });
    } catch (error) {
      console.error(error);
      dispatch({ type: c.POLL_FULFILLED, payload: null });
    }
  };
};

export const updatePoll = (namespaceID, pollID, data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: c.POLL_LOADING });
      await api.put(`/poll-namespaces/${namespaceID}/polls/${pollID}`, data);
      dispatch(fetchPoll(namespaceID, pollID));
      dispatch(toggleSnackbar('Data berhasil disimpan.'));
    } catch (error) {
      console.error(error);
      dispatch(toggleSnackbar('Gagal menyimpan data.'));
    }
  };
};

export const deletePoll = (namespaceID, pollID, history) => {
  return async (dispatch) => {
    try {
      await api.delete(`/poll-namespaces/${namespaceID}/polls`, {
        data: [pollID],
      });

      dispatch(toggleSnackbar('Data berhasil dihapus.'));
      history.goBack();
    } catch (error) {
      console.error(error);
      dispatch(toggleSnackbar('Gagal menghapus data.'));
    }
  };
};
