import React from 'react';
import s from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

const Outer = s.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
    flex-flow: column wrap;
`;

const Loader = props => {
  return (
    <Outer>
      <CircularProgress color="secondary" />
      <Typography variant="h6" style={{ marginTop: 16 }}>
        {props.content}
      </Typography>
    </Outer>
  );
};

Loader.defaultProps = {
  content: 'Mengambil data...',
};

export default Loader;
