import api from '../../utils/api';
import * as c from './constants';

export const fetchStatistics = (type = 'heatmap') => {
  return async (dispatch) => {
    try {
      dispatch({ type: c.STATS_LOADING });
      const { data: res } = await api.get(`/stats/${type}`);
      dispatch({
        type: c.STATS_FULFILLED,
        statsType: type,
        payload: res.data,
      });
    } catch (error) {
      console.error(error);
      dispatch({ type: c.STATS_FULFILLED, statsType: type, payload: [] });
    }
  };
};

export const fetchBirthday = (page = 1) => {
  const thisMonth = new Date().getMonth() + 1;
  const type = 'birthday';

  return async (dispatch) => {
    try {
      dispatch({ type: c.STATS_LOADING });
      const { data: res } = await api.get(
        `/stats/birthday?month=${thisMonth}&page=${page}&limit=10`,
      );
      dispatch({
        type: c.STATS_FULFILLED,
        statsType: type,
        payload: res.data,
        pagination: res.pagination,
      });
    } catch (error) {
      console.error(error);
      dispatch({ type: c.STATS_FULFILLED, statsType: type, payload: [] });
    }
  };
};
