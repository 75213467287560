import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Typography, Divider, Paper, Button } from '@material-ui/core';

import { Table } from '../../../components';
import AddPoll from './AddPoll';
import { pollingColumns } from '../utils';
import { actions } from '../../../reducers';

const Polls = ({ namespaceID, dispatch, polls }) => {
  const history = useHistory();
  const [showModal, toggleModal] = React.useState(false);

  React.useEffect(() => {
    dispatch(actions.fetchPolls(namespaceID));
  }, [dispatch, namespaceID]);

  return (
    <Paper style={{ padding: 16 }}>
      <Typography
        variant="body1"
        style={{ margin: '0 0 16px', fontWeight: 700 }}
      >
        Kategori
      </Typography>
      <Divider style={{ marginBottom: 32 }} />
      <Table
        dense
        stickyHeader
        data={polls}
        columns={pollingColumns}
        onRow={{
          onClick: (_, poll) =>
            history.push(`/d/poll-namespace/${namespaceID}/polls/${poll.id}`),
        }}
        header={
          <div style={{ marginBottom: 16 }}>
            <Button
              color="primary"
              variant="contained"
              size="small"
              onClick={() => toggleModal(true)}
            >
              Tambah Kategori
            </Button>
          </div>
        }
      />
      <AddPoll
        open={showModal}
        namespaceID={namespaceID}
        handleClose={() => toggleModal(false)}
      />
    </Paper>
  );
};

export default connect(({ poll }) => {
  return {
    polls: poll.data,
    loading: poll.loading,
  };
})(Polls);
