import * as c from './constants';

export const initialState = {
  loading: false,
  data: [],
  detail: null,
  pagination: {
    count: 0,
    page: 1,
    limit: 10,
    has_next: false,
  },
  members: [],
  membersPagination: {
    count: 0,
    page: 1,
    limit: 10,
    has_next: false,
  },
};

const commisionReducer = (state = initialState, action) => {
  switch (action.type) {
    case c.COMMISION_LOADING:
      return { ...state, loading: true, data: [], detail: null };
    case c.COMMISIONS_FULFILLED:
      return {
        ...state,
        loading: false,
        data: action.payload,
        pagination: action.pagination,
      };
    case c.COMMISION_FULFILLED:
      return { ...state, loading: false, detail: action.payload };

    case c.COMMISION_MEMBER_FULFILLED:
      return {
        ...state,
        loading: false,
        members: action.payload,
        membersPagination: action.pagination,
      };
    default:
      return state;
  }
};

export default commisionReducer;
