import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Avatar } from '@material-ui/core';
import { actions } from '../../../reducers';

import { Table } from '../../../components';

const UserCommision = ({ dispatch, userID, commisions, loading }) => {
  const history = useHistory();
  React.useEffect(() => {
    dispatch(actions.fetchUserCommision(userID));
  }, [dispatch, userID]);

  return (
    <div style={{ marginBottom: 32 }}>
      <Table
        dense
        title="Persekutuan yang diikuti"
        columns={[
          {
            key: 'commision.picture',
            name: 'Gambar',
            render: (src) => <Avatar src={src} />,
          },
          { key: 'commision.name', name: 'Nama' },
          { key: 'commision.description', name: 'Deskripsi' },
        ]}
        onRow={{
          onClick: (_, row) => history.push(`/d/komisi/${row.commision.id}`),
        }}
        data={commisions}
      />
    </div>
  );
};

export default connect((state) => {
  return {
    commisions: state.user_commision.data,
    loading: state.user_commision.loading,
  };
})(UserCommision);
