import React from 'react';
import { connect } from 'react-redux';
import { Typography, Divider, Paper } from '@material-ui/core';

import { Table } from '../../../components';
import { voteColumns } from '../utils';
import { actions } from '../../../reducers';

const Votes = ({ pollID, optionID, dispatch, votes, pagination }) => {
  const [filter, setFilter] = React.useState({
    page: 1,
    per_page: 10,
  });

  React.useEffect(() => {
    dispatch(actions.fetchVotes(pollID, optionID, filter));
  }, [dispatch, pollID, optionID, filter]);

  const handlePageChange = (_, page) => {
    setFilter({ ...filter, page: page + 1 });
  };

  const handlePerPageChange = ({ target }) => {
    setFilter({ ...filter, page: 1, per_page: target.value });
  };

  return (
    <Paper style={{ padding: 16 }}>
      <Typography
        variant="body1"
        style={{ margin: '0 0 16px', fontWeight: 700 }}
      >
        Votes
      </Typography>
      <Divider style={{ marginBottom: 32 }} />
      <Table
        dense
        stickyHeader
        data={votes}
        columns={voteColumns}
        pagination={{
          perPageOptions: [10, 25, 50, 100],
          perPage: filter.per_page,
          page: filter.page - 1,
          count: pagination ? pagination.count : 1,
          onChangePage: handlePageChange,
          onChangePerPage: handlePerPageChange,
        }}
      />
    </Paper>
  );
};

export default connect(({ vote }) => {
  return {
    votes: vote.data,
    pagination: vote.pagination,
    loading: vote.loading,
  };
})(Votes);
