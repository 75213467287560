import React from 'react';
import { Avatar, Button } from '@material-ui/core';
import format from 'date-fns/format';
import { getImage } from '../../utils/image';

const attendanceRoleMapper = {
  PENGKHOTBAH: 'Pengkhotbah',
  TRANSLATOR: 'Translator',
  HAMBA_TUHAN: 'Hamba Tuhan',
  LITURGIS: 'Liturgis',
  KOLEKTOR: 'Kolektor',
  PETUGAS_PERJAMUAN: 'Petugas Perjamuan',
  DOA_SYAFAAT: 'Doa Syafaat',
  DOA_SYUKUR: 'Doa Syukur',
  DOA_BERKAT: 'Doa Berkat',
  WARTA_JEMAAT: 'Warta Jemaat',
  SINGER: 'Singer',
  MUSIK: 'Musik',
  USHER: 'Usher',
  PETUGAS_REGISTRASI: 'Petugas Registrasi',
  PROTOKOLER_COVID: 'Protokoler COVID',
  MULTIMEDIA: 'Multimedia',
  JEMAAT: 'Jemaat',
  KEAMANAN: 'Keamanan',
  CHOIR: 'Paduan Suara',
  KOORDINATOR_IBADAH: 'Koordinator Ibadah',
  PELAYAN_MINGGU_DEPAN: 'Pelayan Minggu Depan',
};

export const columns = [
  { key: 'worship.description', name: 'Ibadah' },
  { key: 'description', name: 'Deskripsi' },
  { key: 'max_capacity', name: 'Kapasitas', render: (val) => `${val} orang` },
  {
    key: 'started_at',
    name: 'Tanggal Jam',
    render: (val) => {
      const dateTime = new Date(val);
      return format(dateTime, 'dd MMM yyyy HH:mm');
    },
  },
];

export const attendanceColumns = ({ setAttendance }) => [
  {
    key: 'user.picture',
    name: 'Foto',
    render: (src) => <Avatar src={getImage(src)} />,
  },
  { key: 'user.name', name: 'Nama' },
  {
    key: 'attendance_role',
    name: 'Tugas',
    render: (val) => attendanceRoleMapper[val],
  },
  {
    key: 'arrived_at',
    name: 'Jam Kedatangan',
    render: (val) => {
      if (!val) {
        return 'Belum Hadir';
      }

      const dateTime = new Date(val);
      return format(dateTime, 'dd MMM yyyy HH:mm');
    },
  },
  {
    name: 'Kehadiran',
    render: (record) => {
      return (
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={() => setAttendance(record)}
        >
          {record?.arrived_at ? 'Batal Hadir' : 'Tandai Hadir'}
        </Button>
      );
    },
  },
];
