import * as c from './constants';

export const initialState = {
  loading: false,
  overview: null,
  heatmap: [],
  attendances: null,
  birthday: [],
  pagination: {
    count: 0,
    page: 1,
    limit: 10,
    has_next: false,
  },
};

const statsReducer = (state = initialState, action) => {
  switch (action.type) {
    case c.STATS_LOADING:
      return { ...state, loading: true, overview: null, heatmap: [] };
    case c.STATS_FULFILLED:
      return {
        ...state,
        loading: false,
        pagination: action.pagination || state.pagination,
        [action.statsType]: action.payload,
      };
    default:
      return state;
  }
};

export default statsReducer;
