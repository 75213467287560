import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Avatar } from '@material-ui/core';
import { actions } from '../../../reducers';

import { Table } from '../../../components';
import { getImage } from '../../../utils/image';

const relationMapper = {
  FATHER: 'Ayah',
  MOTHER: 'Ibu',
  SPOUSE: 'Pasangan (Suami/Istri)',
  SON: 'Anak (Laki-Laki)',
  DAUGHTER: 'Anak (Perempuan)',
  BROTHER: 'Saudara (Laki-Laki)',
  SISTER: 'Saudara (Perempuan)',
};

const UserRelationship = ({ dispatch, userID, relationships, loading }) => {
  const history = useHistory();

  React.useEffect(() => {
    dispatch(actions.fetchUserRelationships(userID));
  }, [dispatch, userID]);

  return (
    <div style={{ marginBottom: 32 }}>
      <Table
        dense
        title="Relasi Jemaat"
        columns={[
          {
            key: 'relative.picture',
            name: 'Foto',
            render: (src) => <Avatar src={getImage(src)} />,
          },
          { key: 'relative.name', name: 'Nama' },
          {
            key: 'relationship_type',
            name: 'Hubungan',
            render: (relation) => relationMapper[relation],
          },
        ]}
        onRow={{
          onClick: (_, row) => history.push(`/d/jemaat/${row.relative.id}`),
        }}
        data={relationships}
      />
    </div>
  );
};

export default connect((state) => {
  return {
    relationships: state.user_relationship.data,
    loading: state.user_relationship.loading,
  };
})(UserRelationship);
