const base = {
  COOKIE_NAME: 'gepembri-app',
};

const development = {
  ...base,
  API_HOST: 'http://localhost:8000',
  GOOGLE_CLIENT_ID:
    '876245592931-alrcca5ndcr7cjdgqvhn0acs4ftlssrv.apps.googleusercontent.com',
};

const staging = {
  ...base,
  API_HOST: process.env.REACT_APP_API_HOST,
  GOOGLE_CLIENT_ID:
    '876245592931-alrcca5ndcr7cjdgqvhn0acs4ftlssrv.apps.googleusercontent.com',
};

const production = {
  ...base,
  API_HOST: process.env.REACT_APP_API_HOST,
  GOOGLE_CLIENT_ID:
    '876245592931-vmhpffvfqucr8bv7djoi9k4rul1atn2o.apps.googleusercontent.com',
};

const env = process.env.REACT_APP_STAGE || 'development';
export default env === 'production'
  ? production
  : env === 'staging'
  ? staging
  : development;
