import * as c from './constants';

export const initialState = {
  loading: false,
  data: [],
  detail: null,
};

const pollReducer = (state = initialState, action) => {
  switch (action.type) {
    case c.POLL_LOADING:
      return { ...state, loading: true, data: [], detail: null };
    case c.POLLS_FULFILLED:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case c.POLL_FULFILLED:
      return { ...state, loading: false, detail: action.payload };
    default:
      return state;
  }
};

export default pollReducer;
