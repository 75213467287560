import React from 'react';
import { connect } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import {
  Grid,
  Typography,
  Divider,
  Paper,
  TextField,
  Button,
  Switch,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormControlLabel,
  Tabs,
  Tab,
} from '@material-ui/core';
import { DateTimePicker } from '@material-ui/pickers';
import { Bar } from 'react-chartjs-2';

import Polls from './Polls';
import Voters from './Voters';
import { actions } from '../../../reducers';
import { createPollChart } from '../utils';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      {...other}
      style={{ marginTop: 16 }}
    >
      {value === index && children}
    </div>
  );
}

const voterStatsColor = {
  Partisipan: {
    background: '#AED6F1',
    borderColor: '#2E86C1',
  },
  Abstain: {
    background: '#E6B0AA',
    borderColor: '#A93226',
  },
  Memilih: {
    background: '#ABEBC6',
    borderColor: '#28B463',
  },
};

const EditNamespace = ({
  dispatch,
  match,
  history,
  namespace,
  stats,
  voterStats,
}) => {
  const { handleSubmit, register, errors, control } = useForm();
  const [deleteConfirm, showDeleteConfirm] = React.useState(false);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    dispatch(actions.fetchPollNamespace(match.params.id));
    dispatch(actions.fetchPollNamespaceStats(match.params.id));
    dispatch(actions.fetchVoterStats(match.params.id));
  }, [dispatch, match.params.id]);

  const onSubmit = (values) => {
    dispatch(actions.updatePollNamespace(match.params.id, values));
  };

  return (
    <React.Fragment>
      <Grid container spacing={2} style={{ marginTop: 8 }}>
        <Grid item md={3}>
          {namespace ? (
            <Paper style={{ padding: 16 }}>
              <Typography
                variant="body1"
                style={{ margin: '0 0 16px', fontWeight: 700 }}
              >
                Ubah Data
              </Typography>
              <Divider style={{ marginBottom: 32 }} />
              <form onSubmit={handleSubmit(onSubmit)}>
                <div style={{ marginBottom: 16 }}>
                  <TextField
                    fullWidth
                    label="Judul"
                    name="title"
                    size="small"
                    variant="outlined"
                    defaultValue={namespace.title}
                    inputRef={register({ required: true })}
                    error={errors.hasOwnProperty('title')}
                  />
                </div>
                <div style={{ marginBottom: 16 }}>
                  <TextField
                    fullWidth
                    label="Deskripsi"
                    name="description"
                    size="small"
                    variant="outlined"
                    multiline
                    defaultValue={namespace.description}
                    rows={4}
                    inputRef={register({ required: true })}
                    error={errors.hasOwnProperty('description')}
                  />
                </div>
                <div style={{ marginBottom: 16 }}>
                  <Controller
                    as={
                      <DateTimePicker
                        inputVariant="outlined"
                        style={{ width: '100%' }}
                        ampm={false}
                        margin="normal"
                        label="Deadline"
                        minDate={new Date()}
                        format="dd MMM yyyy HH:mm"
                      />
                    }
                    name="deadline"
                    control={control}
                    defaultValue={new Date(namespace.deadline)}
                  />
                </div>
                <div style={{ marginBottom: 16 }}>
                  <Controller
                    as={
                      <FormControlLabel
                        control={<Switch color="primary" />}
                        label="Tampilkan"
                      />
                    }
                    name="is_published"
                    control={control}
                    defaultValue={namespace.is_published}
                  />
                </div>
                <div style={{ marginBottom: 16 }}>
                  <Controller
                    as={
                      <FormControlLabel
                        control={<Switch color="primary" />}
                        label="Ijinkan Memilih"
                      />
                    }
                    name="is_available_to_vote"
                    control={control}
                    defaultValue={namespace.is_available_to_vote}
                  />
                </div>
                <Divider />
                <div style={{ margin: '16px 0' }}>
                  <Button variant="contained" color="primary" type="submit">
                    Simpan
                  </Button>
                </div>
              </form>
            </Paper>
          ) : null}
          <Paper style={{ padding: 16, marginTop: 16 }}>
            <Typography
              variant="body1"
              style={{ margin: '0 0 16px', fontWeight: 700 }}
            >
              Hapus
            </Typography>
            <Divider />
            <div style={{ margin: '8px 0' }}>
              <Typography variant="caption">
                Menghapus polling akan menghapus semua kategori, peserta dan
                pilihan di dalam polling.
              </Typography>
            </div>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                showDeleteConfirm(true);
              }}
            >
              Hapus Polling
            </Button>
            <Dialog
              open={deleteConfirm}
              onClose={() => showDeleteConfirm(false)}
            >
              <DialogTitle id="alert-dialog-title">Hapus Polling</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Anda yakin akan menghapus polling ini?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => showDeleteConfirm(false)}
                  color="primary"
                >
                  Batal
                </Button>
                <Button
                  onClick={() => {
                    dispatch(actions.deleteNamespace(match.params.id, history));
                  }}
                  color="primary"
                  autoFocus
                >
                  Hapus
                </Button>
              </DialogActions>
            </Dialog>
          </Paper>
        </Grid>
        <Grid item md={9}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="simple tabs example"
          >
            <Tab label="Polling" />
            <Tab label="Statistik" />
          </Tabs>
          <TabPanel value={value} index={0}>
            <Polls namespaceID={match.params.id} />
            <Voters namespaceID={match.params.id} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Grid container spacing={2}>
              <Grid item md={6}>
                <Paper style={{ padding: 16, marginBottom: 16 }}>
                  <Typography variant="h6">Statistik Partisipan</Typography>
                  <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    {voterStats.map((stat) => {
                      return (
                        <Paper
                          key={stat.title}
                          style={{
                            padding: 16,
                            width: '30%',
                            margin: 8,
                            border: '1px solid transparent',
                            ...voterStatsColor[stat.title],
                          }}
                        >
                          <p style={{ margin: '0 0 8px 0' }}>
                            <strong>{stat.title}</strong>
                          </p>
                          <div style={{ fontSize: 48, fontWeight: 800 }}>
                            {stat.total}
                          </div>
                        </Paper>
                      );
                    })}
                  </div>
                </Paper>

                <Paper style={{ padding: 16, marginBottom: 16 }}>
                  <Typography variant="h6">Perolehan Suara</Typography>
                  {stats.map((stat, idx, arr) => {
                    return (
                      <div key={stat.title} style={{ marginTop: 16 }}>
                        <p style={{ margin: '0 0 8px 0' }}>
                          <strong>{stat.title}</strong>
                        </p>
                        <ol
                          style={{
                            margin: 0,
                            padding: '0 0 0 16px',
                            fontSize: 12,
                          }}
                        >
                          {stat.options
                            .sort((a, b) => b.total - a.total)
                            .map((opt, _, arr) => {
                              const total = arr.reduce((a, b) => {
                                a += b.total;
                                return a;
                              }, 0);

                              return (
                                <li key={opt.name}>
                                  <strong>{opt.name}</strong>:{' '}
                                  {((+opt.total / total) * 100)
                                    .toFixed(2)
                                    .replace(/\.00$/, '')}
                                  % atau {opt.total} dari {total} suara.
                                </li>
                              );
                            })}
                        </ol>
                      </div>
                    );
                  })}
                </Paper>
              </Grid>
              <Grid item md={6}>
                {stats.map((stat, idx) => {
                  return (
                    <Paper key={idx} style={{ padding: 16, marginBottom: 16 }}>
                      <Typography variant="h6" style={{ marginBottom: 16 }}>
                        {stat.title}
                      </Typography>
                      <Bar
                        data={createPollChart(stat.options)}
                        options={{
                          legend: {
                            display: false,
                          },
                          scales: {
                            yAxes: [
                              {
                                ticks: {
                                  beginAtZero: true,
                                  min: 0,
                                },
                              },
                            ],
                          },
                        }}
                      />
                    </Paper>
                  );
                })}
              </Grid>
            </Grid>
          </TabPanel>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default connect((state) => {
  return {
    loading: state.poll_namespace.loading,
    namespace: state.poll_namespace.detail,
    stats: state.poll_namespace.stats,
    voterStats: state.poll_namespace.voterStats,
  };
})(EditNamespace);
