import { Cookies } from 'react-cookie';
import format from 'date-fns/format';

import api from '../../utils/api';
import * as c from './constants';
import { toggleSnackbar } from '../ui/actions';
import { getChurch } from '../../utils/token';

const cookies = new Cookies();

export const fetchUsers = (filter) => {
  return async (dispatch) => {
    try {
      const mapper = {
        per_page: 'limit',
      };

      const query = Object.keys(filter)
        .filter((k) => filter[k])
        .map((k) => `${mapper.hasOwnProperty(k) ? mapper[k] : k}=${filter[k]}`)
        .join('&');

      dispatch({ type: c.USER_LOADING });
      const { data: res } = await api.get(`/users/search?${query}`);
      dispatch({
        type: c.USERS_FULFILLED,
        payload: res.data,
        pagination: res.pagination,
      });
    } catch (error) {
      console.error(error);
      dispatch({ type: c.USERS_FULFILLED, payload: [] });
    }
  };
};

export const fetchUser = (userID) => {
  return async (dispatch) => {
    try {
      dispatch({ type: c.USER_LOADING });
      const { data: res } = await api.get(`/users/${userID}`);
      dispatch({ type: c.USER_FULFILLED, payload: res.data });
    } catch (error) {
      console.error(error);
      dispatch({ type: c.USER_FULFILLED, payload: null });
    }
  };
};

export const updateUser = (userID, data, history) => {
  return async (dispatch) => {
    try {
      const cookie = cookies.get('access_token');
      const churchID = getChurch(cookie);
      const dob = format(new Date(data.date_of_birth), 'yyyy-MM-dd');
      const join_date = format(new Date(data.join_date), 'yyyy-MM-dd');
      const dead_at = data.dead_at
        ? format(new Date(data.dead_at), 'yyyy-MM-dd')
        : null;

      const { data: res } = await api.put(`/users/${userID}`, {
        church_id: churchID,
        ...data,
        date_of_birth: dob,
        join_date,
        dead_at,
      });
      if (res.data) {
        history.push(`/d/jemaat/${userID}`);
        dispatch(toggleSnackbar('Data berhasil disimpan.'));
      } else {
        dispatch(toggleSnackbar('Gagal menyimpan data.'));
      }
    } catch (error) {
      console.error(error);
      dispatch(toggleSnackbar('Gagal menyimpan data.'));
    }
  };
};

export const assignAddressToUser = ({ addressID, userID }) => {
  return async (dispatch) => {
    try {
      const { data: res } = await api.post(`/users/${userID}/address`, {
        address_id: addressID,
      });
      if (res.data) {
        dispatch(fetchUser(userID));
        dispatch(toggleSnackbar('Data berhasil disimpan.'));
      } else {
        dispatch(toggleSnackbar('Gagal menyimpan data.'));
      }
    } catch (error) {
      console.error(error);
      dispatch(toggleSnackbar('Gagal menyimpan data.'));
    }
  };
};

export const removeUser = (userID, history) => {
  return async (dispatch) => {
    try {
      const { data: res } = await api.delete('/users/', {
        data: [userID],
      });
      if (res.data.length) {
        history.push('/d/jemaat');
        dispatch(fetchUsers());
        dispatch(toggleSnackbar('Data berhasil dihapus.'));
      } else {
        dispatch(toggleSnackbar('Gagal menyimpan data.'));
      }
    } catch (error) {
      console.error(error);
      dispatch(toggleSnackbar('Gagal menyimpan data.'));
    }
  };
};

export const switchOwner = (userID, ownerID) => {
  return async (dispatch) => {
    try {
      const { data: res } = await api.post(`/users/${userID}/owner`, {
        owner_id: ownerID,
      });
      if (res.data) {
        dispatch(fetchUser(userID));
        dispatch(toggleSnackbar('Data berhasil disimpan.'));
      } else {
        dispatch(toggleSnackbar('Gagal menyimpan data.'));
      }
    } catch (error) {
      console.error(error);
      dispatch(toggleSnackbar('Gagal menyimpan data.'));
    }
  };
};
