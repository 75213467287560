import React from 'react';
import { connect } from 'react-redux';
import { useForm } from 'react-hook-form';
import {
  Grid,
  Typography,
  Divider,
  Paper,
  TextField,
  Button,
  Avatar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormHelperText,
} from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import Votes from './Votes';
import { actions } from '../../../reducers';
import uploadImage from '../../../utils/uploadImage';
import { getImage } from '../../../utils/image';

const EditOption = ({
  dispatch,
  match: {
    params: { poll_id: pollID, option_id: optionID },
  },
  history,
  option,
}) => {
  const [isUploading, setUploadStatus] = React.useState(false);
  const [picturePreview, setPreview] = React.useState('');
  const { handleSubmit, register, errors, setValue } = useForm();
  const [deleteConfirm, showDeleteConfirm] = React.useState(false);

  React.useEffect(() => {
    dispatch(actions.fetchOption(pollID, optionID));
  }, [dispatch, pollID, optionID]);

  const onSubmit = (values) => {
    dispatch(actions.updateOption(pollID, optionID, values));
  };

  const handleUpload = ({ target }) => {
    setUploadStatus(true);
    uploadImage(target.files[0], { folder: 'polling_options' })
      .then((res) => {
        setValue('picture', res.secure_url);
        setPreview(res.secure_url);
      })
      .catch(() => {
        dispatch(actions.toggleSnackbar('Gagal menunggah foto!'));
        setValue('');
        setPreview('');
      })
      .finally(() => {
        setUploadStatus(false);
      });
  };

  return (
    <React.Fragment>
      <Grid container spacing={2} style={{ marginTop: 8 }}>
        <Grid item md={4}>
          {option ? (
            <Paper style={{ padding: 16 }}>
              <Typography
                variant="body1"
                style={{ margin: '0 0 16px', fontWeight: 700 }}
              >
                Ubah Data
              </Typography>
              <Divider style={{ marginBottom: 32 }} />
              <form onSubmit={handleSubmit(onSubmit)}>
                <div style={{ marginBottom: 16 }}>
                  <TextField
                    fullWidth
                    label="Nama"
                    name="name"
                    size="small"
                    variant="outlined"
                    defaultValue={option.name}
                    inputRef={register({ required: true })}
                    error={errors.hasOwnProperty('name')}
                  />
                </div>
                <div style={{ marginBottom: 16 }}>
                  <TextField
                    fullWidth
                    label="Deskripsi"
                    name="description"
                    size="small"
                    variant="outlined"
                    multiline
                    defaultValue={option.description}
                    rows={4}
                    inputRef={register}
                    error={errors.hasOwnProperty('description')}
                  />
                </div>
                <div style={{ marginBottom: 16 }}>
                  <TextField
                    fullWidth
                    label="Nama"
                    name="name_zh"
                    size="small"
                    variant="outlined"
                    defaultValue={option.name_zh}
                    inputRef={register}
                    error={errors.hasOwnProperty('name_zh')}
                  />
                </div>
                <div style={{ marginBottom: 16 }}>
                  <TextField
                    fullWidth
                    label="Deskripsi"
                    name="description_zh"
                    size="small"
                    variant="outlined"
                    multiline
                    defaultValue={option.description_zh}
                    rows={4}
                    inputRef={register}
                    error={errors.hasOwnProperty('description_zh')}
                  />
                </div>
                <div style={{ marginBottom: 32 }}>
                  <FormHelperText style={{ marginBottom: 16 }}>
                    Pilih Gambar
                  </FormHelperText>
                  <input
                    accept="image/*"
                    id="input-file"
                    type="file"
                    style={{ display: 'none' }}
                    onChange={handleUpload}
                    onClick={(e) => (e.target.value = null)}
                  />

                  <input
                    name="picture"
                    type="text"
                    style={{ display: 'none' }}
                    value={picturePreview || option.picture}
                    onChange={() => {}}
                    ref={register({ required: true })}
                  />
                  {errors.hasOwnProperty('picture') ? (
                    <FormHelperText error style={{ marginBottom: 16 }}>
                      Gambar tidak boleh kosong!
                    </FormHelperText>
                  ) : null}
                  {picturePreview || option.picture ? (
                    <Avatar
                      variant="rounded"
                      style={{ width: 400, height: 'auto', marginTop: 16 }}
                      alt="Uploads"
                      src={getImage(picturePreview || option.picture)}
                    />
                  ) : null}
                </div>
                <label htmlFor="input-file">
                  <Button
                    variant="contained"
                    component="span"
                    size="small"
                    startIcon={<CloudUploadIcon />}
                  >
                    {isUploading ? 'Sedang mengunggah...' : 'Upload Gambar'}
                  </Button>
                </label>
                <Divider />
                <div style={{ margin: '16px 0' }}>
                  <Button variant="contained" color="primary" type="submit">
                    Simpan
                  </Button>
                </div>
              </form>
            </Paper>
          ) : null}
          <Paper style={{ padding: 16, marginTop: 16 }}>
            <Typography
              variant="body1"
              style={{ margin: '0 0 16px', fontWeight: 700 }}
            >
              Hapus
            </Typography>
            <Divider />
            <div style={{ margin: '8px 0' }}>
              <Typography variant="caption">
                Menghapus kandidat/pilihan akan menghapus semua pemilih
                kandidat/pilihan polling ini.
              </Typography>
            </div>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                showDeleteConfirm(true);
              }}
            >
              Hapus Kandidat/Pilihan
            </Button>
            <Dialog
              open={deleteConfirm}
              onClose={() => showDeleteConfirm(false)}
            >
              <DialogTitle id="alert-dialog-title">
                Hapus Kandidat/Pilihan
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Anda yakin akan menghapus kandidat/pilihan ini?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => showDeleteConfirm(false)}
                  color="primary"
                >
                  Batal
                </Button>
                <Button
                  onClick={() => {
                    dispatch(
                      actions.deleteOptions(pollID, [optionID], history),
                    );
                  }}
                  color="primary"
                  autoFocus
                >
                  Hapus
                </Button>
              </DialogActions>
            </Dialog>
          </Paper>
        </Grid>
        <Grid item md={8}>
          <Votes pollID={pollID} optionID={optionID} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default connect((state) => {
  return {
    loading: state.option.loading,
    option: state.option.detail,
  };
})(EditOption);
