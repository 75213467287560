import { combineReducers } from 'redux';

import ui from './ui';
import user from './user';
import commision from './commision';
import stats from './stats';
import user_commision from './user-commision';
import user_relationship from './user-relationship';
import worship from './worship';
import schedule from './schedule';
import attendance from './attendance';
import poll_namespace from './poll-namespace';
import poll from './poll';
import option from './option';
import voter from './voter';
import vote from './vote';

const createRootReducer = () =>
  combineReducers({
    ui: ui.reducer,
    user: user.reducer,
    commision: commision.reducer,
    stats: stats.reducer,
    user_commision: user_commision.reducer,
    user_relationship: user_relationship.reducer,
    worship: worship.reducer,
    schedule: schedule.reducer,
    attendance: attendance.reducer,
    poll_namespace: poll_namespace.reducer,
    poll: poll.reducer,
    option: option.reducer,
    voter: voter.reducer,
    vote: vote.reducer,
  });

export const actions = {
  ...ui.actions,
  ...user.actions,
  ...commision.actions,
  ...stats.actions,
  ...user_commision.actions,
  ...user_relationship.actions,
  ...worship.actions,
  ...schedule.actions,
  ...attendance.actions,
  ...poll_namespace.actions,
  ...poll.actions,
  ...option.actions,
  ...voter.actions,
  ...vote.actions,
};

export default createRootReducer;
