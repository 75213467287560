import React from 'react';
import debounce from 'lodash/debounce';
import { useForm, Controller } from 'react-hook-form';
import { connect } from 'react-redux';
import {
  Dialog,
  Divider,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import { actions } from '../../../reducers';
import api from '../../../utils/api';

const InviteUser = ({ dispatch, open, handleClose, scheduleID }) => {
  const { handleSubmit, register, errors, control, setValue } = useForm();

  const [options, setOptions] = React.useState([]);

  React.useEffect(() => {
    register('user_id');
  }, [register]);

  const fetchData = debounce((name) => {
    api
      .get(`/users/search?name=${name}`)
      .then(({ data: res }) => {
        setOptions(res.data);
      })
      .catch(() => setOptions([]));
  }, 400);

  const handleSearchUser = ({ target }) => {
    if (target.value) {
      fetchData(target.value);
    } else {
      setOptions([]);
    }
  };

  const onSubmit = (values) => {
    dispatch(
      actions.createAttendance(scheduleID, {
        ...values,
        schedule_id: scheduleID,
      })
    );
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Undang Jemaat</DialogTitle>
      <Divider style={{ marginBottom: 16 }} />
      <DialogContent style={{ width: 500 }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div style={{ marginBottom: 16 }}>
            <FormControl fullWidth>
              <Autocomplete
                onChange={(_, selected) => {
                  if (selected) {
                    setValue('user_id', selected.id);
                  }
                }}
                getOptionSelected={(option, value) =>
                  option.name === value.name
                }
                getOptionLabel={(option) => option.name}
                options={options}
                error={errors.hasOwnProperty('user_id')}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Ketik nama jemaat..."
                    fullWidth
                    variant="outlined"
                    onChange={handleSearchUser}
                    InputProps={params.InputProps}
                  />
                )}
              />
            </FormControl>
          </div>
          <div style={{ marginBottom: 16 }}>
            <FormControl
              variant="outlined"
              margin="normal"
              style={{ width: '100%' }}
            >
              <InputLabel>Sebagai</InputLabel>
              <Controller
                as={
                  <Select variant="outlined" label="Kebaktian">
                    <MenuItem value="PENGKHOTBAH">Pengkhotbah</MenuItem>
                    <MenuItem value="TRANSLATOR">Translator</MenuItem>
                    <MenuItem value="HAMBA_TUHAN">Hamba Tuhan</MenuItem>
                    <MenuItem value="LITURGIS">Liturgis</MenuItem>
                    <MenuItem value="KOLEKTOR">Kolektor</MenuItem>
                    <MenuItem value="PETUGAS_PERJAMUAN">
                      Petugas Perjamuan
                    </MenuItem>
                    <MenuItem value="DOA_SYAFAAT">Doa Syafaat</MenuItem>
                    <MenuItem value="DOA_SYUKUR">Doa Syukur</MenuItem>
                    <MenuItem value="DOA_BERKAT">Doa Berkat</MenuItem>
                    <MenuItem value="WARTA_JEMAAT">Warta Jemaat</MenuItem>
                    <MenuItem value="MUSIK">Musik</MenuItem>
                    <MenuItem value="SINGER">Singer</MenuItem>
                    <MenuItem value="USHER">Usher</MenuItem>
                    <MenuItem value="PETUGAS_REGISTRASI">
                      Petugas Registrasi
                    </MenuItem>
                    <MenuItem value="PROTOKOLER_COVID">
                      Protokoler COVID
                    </MenuItem>
                    <MenuItem value="CHOIR">Paduan Suara</MenuItem>
                    <MenuItem value="KEAMANAN">Keamanan</MenuItem>
                    <MenuItem value="MULTIMEDIA">Multimedia</MenuItem>
                    <MenuItem value="KOORDINATOR_IBADAH">
                      Koordinator Ibadah
                    </MenuItem>
                    <MenuItem value="PELAYAN_MINGGU_DEPAN">
                      Pelayan Minggu Depan
                    </MenuItem>
                    <MenuItem value="JEMAAT">Jemaat</MenuItem>
                  </Select>
                }
                name="attendance_role"
                control={control}
                error={errors.hasOwnProperty('attendance_role')}
                onChange={([selected]) => {
                  return selected.target.value;
                }}
              />
            </FormControl>
          </div>
          <Divider />
          <div style={{ margin: '16px 0' }}>
            <Button
              variant="contained"
              onClick={handleClose}
              style={{ marginRight: 8 }}
            >
              Batal
            </Button>
            <Button variant="contained" color="primary" type="submit">
              Undang
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default connect()(InviteUser);
