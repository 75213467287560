import React from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import {
  Dialog,
  Divider,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
} from '@material-ui/core';

import { actions } from '../../../reducers';

const AddPoll = ({ dispatch, open, handleClose, namespaceID }) => {
  const { handleSubmit, register, errors } = useForm();

  const onSubmit = (values) => {
    dispatch(actions.createPoll(namespaceID, values));
    handleClose();
  };
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Buat Baru</DialogTitle>
      <Divider style={{ marginBottom: 16 }} />
      <DialogContent style={{ width: 500 }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div style={{ marginBottom: 16 }}>
            <TextField
              fullWidth
              label="Judul"
              name="title"
              size="small"
              variant="outlined"
              inputRef={register({ required: true })}
              error={errors.hasOwnProperty('title')}
            />
          </div>
          <div style={{ marginBottom: 16 }}>
            <TextField
              fullWidth
              label="Judul (dalam Mandarin)"
              name="title_zh"
              size="small"
              variant="outlined"
              inputRef={register}
              error={errors.hasOwnProperty('title_zh')}
            />
          </div>
          <div style={{ marginBottom: 16 }}>
            <TextField
              fullWidth
              label="Deskripsi"
              name="description"
              size="small"
              variant="outlined"
              multiline
              rows={4}
              inputRef={register}
              error={errors.hasOwnProperty('description')}
            />
          </div>
          <div style={{ marginBottom: 16 }}>
            <TextField
              fullWidth
              label="Deskripsi (dalam Mandarin)"
              name="description_zh"
              size="small"
              variant="outlined"
              multiline
              rows={4}
              inputRef={register}
              error={errors.hasOwnProperty('description_zh')}
            />
          </div>
          <div style={{ marginBottom: 16 }}>
            <TextField
              fullWidth
              label="Min. Pilihan"
              name="min_selection"
              size="small"
              type="number"
              variant="outlined"
              inputRef={register({ required: true })}
              error={errors.hasOwnProperty('min_selection')}
            />
          </div>
          <div style={{ marginBottom: 16 }}>
            <TextField
              fullWidth
              label="Max. Pilihan"
              name="max_selection"
              size="small"
              type="number"
              variant="outlined"
              inputRef={register({ required: true })}
              error={errors.hasOwnProperty('max_selection')}
            />
          </div>
          <Divider />
          <div style={{ margin: '16px 0' }}>
            <Button variant="contained" color="primary" type="submit">
              Simpan
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default connect()(AddPoll);
