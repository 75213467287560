import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Typography,
  Divider,
  Paper,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';

import { Table } from '../../../components';
import AddOption from './AddOption';
import { optionColumns } from '../utils';
import { actions } from '../../../reducers';

const Options = ({ dispatch, options, namespaceID, pollID }) => {
  const history = useHistory();
  const [showModal, toggleModal] = React.useState(false);
  const [selected, setSelected] = React.useState([]);
  const [deleteConfirm, showDeleteConfirm] = React.useState(false);

  React.useEffect(() => {
    dispatch(actions.fetchOptions(pollID));
  }, [dispatch, pollID]);

  const handleDeleteOptions = () => {
    dispatch(actions.deleteOptions(pollID, selected));
    showDeleteConfirm(false);
  };

  return (
    <Paper style={{ padding: 16 }}>
      <Typography
        variant="body1"
        style={{ margin: '0 0 16px', fontWeight: 700 }}
      >
        Pilihan/Kandidat
      </Typography>
      <Divider style={{ marginBottom: 32 }} />
      <Table
        dense
        stickyHeader
        data={options}
        columns={optionColumns}
        onRow={{
          onClick: (_, option) =>
            history.push(
              `/d/poll-namespace/${namespaceID}/polls/${pollID}/options/${option.id}`,
            ),
        }}
        getSelectedRow={(rows) => setSelected(rows.map((r) => r.id))}
        header={
          <div style={{ marginBottom: 16 }}>
            <Button
              color="secondary"
              variant="contained"
              size="small"
              disabled={selected.length === 0}
              onClick={() => showDeleteConfirm(true)}
              style={{ marginRight: 8 }}
            >
              Hapus
            </Button>

            <Button
              color="primary"
              variant="contained"
              size="small"
              onClick={() => toggleModal(true)}
            >
              Tambah Pilihan
            </Button>
          </div>
        }
      />
      <Dialog open={deleteConfirm} onClose={() => showDeleteConfirm(false)}>
        <DialogTitle id="alert-dialog-title">
          Hapus Pilihan/Kandidat
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Anda yakin akan menghapus pilihan/kandidat?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => showDeleteConfirm(false)} color="primary">
            Batal
          </Button>
          <Button onClick={handleDeleteOptions} color="primary" autoFocus>
            Hapus
          </Button>
        </DialogActions>
      </Dialog>
      <AddOption
        open={showModal}
        pollID={pollID}
        handleClose={() => toggleModal(false)}
      />
    </Paper>
  );
};

export default connect(({ option }) => {
  return {
    options: option.data,
    loading: option.loading,
  };
})(Options);
