import React from 'react';
import { Avatar } from '@material-ui/core';
import format from 'date-fns/format';

import { getImage } from '../../utils/image';

export const genderMapper = {
  MALE: 'Laki-Laki',
  FEMALE: 'Perempuan',
};

export const maritalStatusMapper = {
  SINGLE: 'Belum Menikah',
  MARRIED: 'Menikah',
  DIVORCED: 'Cerai',
  WIDOWER: 'Duda',
  WIDOWED: 'Janda',
};

export const religionMapper = {
  KRISTEN: 'Kristen Protestan',
  KATHOLIK: 'Katholik',
  BUDDHA: 'Buddha',
  KONGHUCU: 'Konghucu',
  ISLAM: 'Islam',
  HINDU: 'Hindu',
};

export const membershipMapper = {
  PERMANENT: 'Jemaat Tetap',
  TEMPORARY: 'Simpatisan',
};

export const columns = [
  {
    key: 'picture',
    name: 'Foto',
    render: (src) => <Avatar src={getImage(src)} />,
  },
  {
    name: 'Nama',
    render: (record) => {
      const mandarinName = record.mandarin_name
        ? ` (${record.mandarin_name})`
        : '';
      return record.name + mandarinName;
    },
  },
  { key: 'nickname', name: 'Panggilan' },
  {
    key: 'date_of_birth',
    name: 'Tanggal Lahir',
    render: (text) => format(new Date(text), 'dd MMM yyyy'),
  },
  { key: 'age', name: 'Umur', render: (age) => `${age} tahun` },
  { key: 'phone', name: 'No HP' },
  {
    key: 'gender',
    name: 'Jenis Kelamin',
    render: (value) => genderMapper[value],
  },

  {
    name: 'Bergabung Sejak',
    render: (value) => {
      if (!value.join_date) {
        return '-';
      }

      return (
        format(new Date(value.join_date), 'MMM yyyy') +
        ` (~${value.long_stay} thn)`
      );
    },
  },
  {
    key: 'membership',
    name: 'Keanggotaan',
    render: (value) => membershipMapper[value],
  },
  {
    key: 'marital_status',
    name: 'Status Perkawinan',
    render: (value) => maritalStatusMapper[value],
  },
  {
    key: 'address',
    name: 'Wilayah',
    render: (value) =>
      value ? `${value.province}, ${value.regency}, ${value.district}` : null,
  },
];
