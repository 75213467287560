import React from 'react';
import { connect } from 'react-redux';
import {
  Typography,
  Divider,
  Paper,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Avatar,
} from '@material-ui/core';

import { Table } from '../../../components';
import AddVoter from './AddVoter';
import { voterColumns } from '../utils';
import { actions } from '../../../reducers';

const Voters = ({ namespaceID, dispatch, voters, pagination, query }) => {
  const [showModal, toggleModal] = React.useState(false);
  const [selected, setSelected] = React.useState([]);
  const [deleteConfirm, showDeleteConfirm] = React.useState(false);
  const [votes, setVotes] = React.useState([]);
  const [filter, setFilter] = React.useState({
    page: 1,
    per_page: 10,
  });

  React.useEffect(() => {
    dispatch(actions.fetchVoters(namespaceID, filter));
  }, [dispatch, namespaceID, filter]);

  const handleDeleteOptions = () => {
    dispatch(actions.deleteVoters(namespaceID, selected));
    showDeleteConfirm(false);
    setSelected([]);
  };

  const handlePageChange = (_, page) => {
    setFilter({ ...filter, page: page + 1 });
  };

  const handlePerPageChange = ({ target }) => {
    setFilter({ ...filter, page: 1, per_page: target.value });
  };

  return (
    <Paper style={{ padding: 16, marginTop: 16 }}>
      <Typography
        variant="body1"
        style={{ margin: '0 0 16px', fontWeight: 700 }}
      >
        Partisipan
      </Typography>
      <Divider style={{ marginBottom: 32 }} />
      <Table
        dense
        stickyHeader
        data={voters}
        columns={voterColumns}
        getSelectedRow={(rows) => setSelected(rows.map((r) => r.id))}
        header={
          <div style={{ marginBottom: 16 }}>
            <Button
              color="secondary"
              variant="contained"
              size="small"
              disabled={selected.length === 0}
              onClick={() => showDeleteConfirm(true)}
              style={{ marginRight: 8 }}
            >
              Hapus Partisipan
            </Button>

            <Button
              color="primary"
              variant="contained"
              size="small"
              onClick={() => toggleModal(true)}
            >
              Undang Partisipan
            </Button>
          </div>
        }
        onRow={{
          onClick: (_, row) => setVotes(row.votes),
        }}
        pagination={{
          perPageOptions: [10, 25, 50, 100],
          perPage: filter.per_page,
          page: filter.page - 1,
          count: pagination ? pagination.count : 1,
          onChangePage: handlePageChange,
          onChangePerPage: handlePerPageChange,
        }}
      />
      <Dialog open={deleteConfirm} onClose={() => showDeleteConfirm(false)}>
        <DialogTitle id="alert-dialog-title">
          Hapus Pilihan/Kandidat
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Anda yakin akan menghapus pilihan/kandidat?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => showDeleteConfirm(false)} color="primary">
            Batal
          </Button>
          <Button onClick={handleDeleteOptions} color="primary" autoFocus>
            Hapus
          </Button>
        </DialogActions>
      </Dialog>
      <AddVoter
        open={showModal}
        namespaceID={namespaceID}
        handleClose={() => toggleModal(false)}
      />
      <Dialog
        onClose={() => setVotes([])}
        open={votes.length}
        maxWidth={550}
        style={{ minWidth: 300 }}
      >
        <DialogTitle onClose={() => setVotes([])}>
          Pilihan Partisipan
        </DialogTitle>
        <DialogContent dividers>
          {votes.length
            ? votes.map((vote, idx) => {
                return (
                  <div
                    key={vote.id}
                    style={{
                      display: 'flex',
                      marginBottom: 12,
                      alignItems: 'center',
                    }}
                  >
                    <Typography>{idx + 1}. </Typography>
                    <Avatar
                      src={vote.option.picture}
                      style={{ margin: '0 8px' }}
                    />
                    <Typography>
                      {vote.option.name}{' '}
                      {vote.option.name_zh ? `(${vote.option.name_zh})` : ''}
                    </Typography>
                  </div>
                );
              })
            : null}
        </DialogContent>
      </Dialog>
    </Paper>
  );
};

export default connect(({ voter }) => {
  return {
    voters: voter.data,
    pagination: voter.pagination,
    loading: voter.loading,
  };
})(Voters);
