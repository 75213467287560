import React from 'react';
import { connect } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import format from 'date-fns/format';
import {
  Grid,
  Paper,
  Typography,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Avatar,
  Divider,
  FormHelperText,
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { actions } from '../../../reducers';
import uploadImage from '../../../utils/uploadImage';
import { AddressCard } from '../../../components';
import { getImage } from '../../../utils/image';
import occupations from '../occupations.json';

import RelationshipCard from './Relationship';
import CommisionCard from './Commision';
import OwnerCard from './Owner';

const EditJemaat = ({ match, dispatch, history, loading, user }) => {
  const [isUploading, setUploadStatus] = React.useState(false);
  const [isBaptise, setBaptise] = React.useState(false);
  const { handleSubmit, register, errors, control, setValue } = useForm();
  const [picturePreview, setPreview] = React.useState('');

  const handleBaptise = (e) => {
    setBaptise(e.target.checked);
  };

  React.useEffect(() => {
    dispatch(actions.fetchUser(match.params.id));
  }, [dispatch, match.params.id]);

  React.useEffect(() => {
    if (user) {
      setBaptise(user.is_baptised);
    }
  }, [user]);

  React.useEffect(() => {
    register('occupation');
  }, [register]);

  const handleUpload = ({ target }) => {
    setUploadStatus(true);
    uploadImage(target.files[0], { folder: 'jemaat' })
      .then((res) => {
        setValue('picture', res.secure_url);
        setPreview(res.secure_url);
      })
      .catch(() => {
        dispatch(actions.toggleSnackbar('Gagal menunggah foto!'));
      })
      .finally(() => {
        setUploadStatus(false);
      });
  };

  const onSubmit = (values) => {
    dispatch(actions.updateUser(match.params.id, values, history));
  };

  return (
    <Grid container spacing={2} style={{ marginTop: 8 }}>
      <Grid item md={5}>
        {user ? (
          <React.Fragment>
            <Paper style={{ padding: 16 }}>
              <Typography
                variant="body1"
                style={{ margin: '0 0 16px', fontWeight: 700 }}
              >
                Ubah Data
              </Typography>
              <Divider style={{ marginBottom: 16 }} />

              <form onSubmit={handleSubmit(onSubmit)}>
                <TextField
                  fullWidth
                  name="name"
                  label="Nama"
                  helperText="Nama sesuai KTP"
                  margin="normal"
                  error={errors.hasOwnProperty('name')}
                  defaultValue={user.name}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputRef={register({ required: true })}
                />
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <TextField
                    name="nickname"
                    label="Nama Panggilan"
                    margin="normal"
                    error={errors.hasOwnProperty('name')}
                    defaultValue={user.nickname}
                    style={{ width: '48%' }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputRef={register({ required: true })}
                  />
                  <TextField
                    name="mandarin_name"
                    label="Nama Mandarin"
                    margin="normal"
                    error={errors.hasOwnProperty('mandarin_name')}
                    defaultValue={user.mandarin_name}
                    style={{ width: '48%', marginLeft: 16 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputRef={register}
                  />
                </div>
                <TextField
                  fullWidth
                  name="member_reg_no"
                  label="No Anggota"
                  helperText="Kosongkan jika tidak ada"
                  margin="normal"
                  error={errors.hasOwnProperty('member_reg_no')}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputRef={register}
                  defaultValue={user.member_reg_no}
                />
                <FormControl style={{ minWidth: '100%' }} margin="normal">
                  <InputLabel shrink>Jenis Kelamin</InputLabel>
                  <Controller
                    as={
                      <Select>
                        <MenuItem value="MALE">Laki-Laki</MenuItem>
                        <MenuItem value="FEMALE">Perempuan</MenuItem>
                      </Select>
                    }
                    name="gender"
                    control={control}
                    onChange={([selected]) => {
                      return selected.target.value;
                    }}
                    defaultValue={user.gender}
                  />
                </FormControl>
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <TextField
                    name="birth_place"
                    margin="normal"
                    label="Tempat Lahir"
                    helperText="Tempat lahir sesuai KTP"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={errors.hasOwnProperty('birth_place')}
                    defaultValue={user.birth_place}
                    inputRef={register({ required: true })}
                  />
                  <Controller
                    as={
                      <DatePicker
                        style={{ minWidth: '48%', marginLeft: 16 }}
                        margin="normal"
                        format="dd MMM yyyy"
                        label="Tanggal Lahir"
                      />
                    }
                    name="date_of_birth"
                    control={control}
                    onChange={([selected]) => {
                      return format(selected, 'MM/dd/yyyy');
                    }}
                    defaultValue={format(
                      new Date(user.date_of_birth),
                      'MM/dd/yyyy'
                    )}
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <TextField
                    fullWidth
                    name="phone"
                    label="No. Handphone"
                    helperText="No HP jika ada (opsional)"
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    defaultValue={user.phone}
                    inputRef={register}
                  />
                  <div
                    style={{
                      minWidth: '38%',
                      marginLeft: 16,
                      textAlign: 'center',
                    }}
                  >
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => window.open(`https://wa.me/${user.phone}`)}
                      startIcon={<WhatsAppIcon />}
                    >
                      Hubungi via WA
                    </Button>
                  </div>
                </div>
                <Autocomplete
                  fullWidth
                  options={occupations}
                  getOptionLabel={(option) => {
                    return option;
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Pilih Pekerjaan" />
                  )}
                  onSelect={({ target }) =>
                    setValue('occupation', target.value)
                  }
                  defaultValue={user.occupation}
                />

                <TextField
                  fullWidth
                  name="field_of_work"
                  label="Bidang Pekerjaan/Perusahaan"
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputRef={register}
                  defaultValue={user.field_of_work}
                />

                <FormControl margin="normal">
                  <InputLabel shrink>Status Baptis</InputLabel>
                  <FormControlLabel
                    style={{ marginTop: 16 }}
                    control={
                      <Switch
                        color="primary"
                        name="is_baptised"
                        onChange={handleBaptise}
                        defaultChecked={user.is_baptised}
                        inputRef={register}
                      />
                    }
                    label="Sudah Baptis"
                  />
                </FormControl>
                {isBaptise ? (
                  <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <TextField
                      fullWidth
                      name="baptise_church"
                      label="Gereja tempat pembaptisan"
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputRef={register}
                      style={{ minWidth: '48%' }}
                      defaultValue={user.baptise_church}
                    />
                    <TextField
                      fullWidth
                      name="baptise_year"
                      label="Tahun Baptis"
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputRef={register}
                      style={{ minWidth: '48%', marginLeft: 16 }}
                      defaultValue={user.baptise_year}
                    />
                  </div>
                ) : null}
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <FormControl style={{ minWidth: '48%' }} margin="normal">
                    <InputLabel shrink>Status Perkawinan</InputLabel>
                    <Controller
                      as={
                        <Select>
                          <MenuItem value="SINGLE">Belum Menikah</MenuItem>
                          <MenuItem value="MARRIED">Menikah</MenuItem>
                          <MenuItem value="DIVORCED">Cerai</MenuItem>
                          <MenuItem value="WIDOWER">Duda</MenuItem>
                          <MenuItem value="WIDOWED">Janda</MenuItem>
                        </Select>
                      }
                      name="marital_status"
                      control={control}
                      onChange={([selected]) => {
                        return { value: selected.target.value };
                      }}
                      defaultValue={user.marital_status}
                    />
                  </FormControl>
                  <FormControl
                    style={{ minWidth: '48%', marginLeft: 16 }}
                    margin="normal"
                  >
                    <InputLabel shrink>Status Keanggotaan</InputLabel>
                    <Controller
                      as={
                        <Select>
                          <MenuItem value="PERMANENT">Tetap</MenuItem>
                          <MenuItem value="TEMPORARY">Simpatisan</MenuItem>
                        </Select>
                      }
                      name="membership_status"
                      control={control}
                      onChange={([selected]) => {
                        return { value: selected.target.value };
                      }}
                      defaultValue={user.membership}
                    />
                  </FormControl>
                </div>
                <div>
                  <TextField
                    fullWidth
                    name="church_origin"
                    label="Gereja Asal"
                    margin="normal"
                    helperText="Mohon diisi jika anda belum sebagai anggota tetap Gepembri"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    defaultValue={user.church_origin}
                    inputRef={register}
                    style={{ width: '48%' }}
                  />
                  <Controller
                    as={
                      <DatePicker
                        style={{ minWidth: '100%' }}
                        views={['year', 'month']}
                        margin="normal"
                        label="Bergabung Sejak"
                        maxDate={new Date()}
                      />
                    }
                    name="join_date"
                    control={control}
                    onChange={([selected]) => {
                      return format(selected, 'MM/01/yyyy');
                    }}
                    defaultValue={format(
                      new Date(user.join_date || '01-01-2000'),
                      'MM/dd/yyyy'
                    )}
                  />
                </div>
                <div>
                  <FormControl style={{ minWidth: '48%' }} margin="normal">
                    <InputLabel shrink>Golongan Darah</InputLabel>
                    <Controller
                      as={
                        <Select>
                          <MenuItem value="A">A</MenuItem>
                          <MenuItem value="B">B</MenuItem>
                          <MenuItem value="O">O</MenuItem>
                          <MenuItem value="AB">AB</MenuItem>
                          <MenuItem value="X">Saya Tidak/Belum Tahu</MenuItem>
                        </Select>
                      }
                      name="blood_type"
                      control={control}
                      onChange={([selected]) => {
                        return selected.target.value;
                      }}
                      defaultValue={user.blood_type}
                    />
                  </FormControl>
                  <FormControl
                    style={{ minWidth: '48%', marginLeft: 16 }}
                    margin="normal"
                  >
                    <InputLabel shrink>Bersedia Donor Darah</InputLabel>
                    <FormControlLabel
                      style={{ marginTop: 16 }}
                      control={
                        <Switch
                          color="primary"
                          name="available_for_donor"
                          inputRef={register}
                          defaultChecked={user.available_for_donor}
                        />
                      }
                      label="Bersedia Dihubungi Jika Diperlukan"
                    />
                  </FormControl>
                </div>
                <div>
                  <FormControl style={{ minWidth: '100%' }} margin="normal">
                    <InputLabel shrink>Rhesus Darah</InputLabel>
                    <Controller
                      as={
                        <Select>
                          <MenuItem value="POSITIVE">Positif</MenuItem>
                          <MenuItem value="NEGATIVE">Negatif</MenuItem>
                          <MenuItem value="X">Saya Tidak/Belum Tahu</MenuItem>
                        </Select>
                      }
                      name="blood_rhesus"
                      control={control}
                      onChange={([selected]) => {
                        return selected.target.value;
                      }}
                      defaultValue={user?.blood_rhesus || "X"}
                    />
                  </FormControl>
                </div>
                <div>
                  <FormControl style={{ minWidth: '48%' }} margin="normal">
                    <InputLabel shrink>Telah Divaksin Covid-19</InputLabel>
                    <FormControlLabel
                      style={{ marginTop: 16 }}
                      control={
                        <Switch
                          color="primary"
                          name="is_c19_vaccinated"
                          defaultChecked={user.is_c19_vaccinated}
                          inputRef={register}
                        />
                      }
                      label="Telah Divaksin Covid-19"
                    />
                  </FormControl>
                </div>
                <div style={{ margin: '16px 0' }}>
                  <label htmlFor="input-file">
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                    >
                      {isUploading ? 'Sedang mengunggah...' : 'Upload Foto'}
                    </Button>
                  </label>
                  <input
                    accept="image/*"
                    id="input-file"
                    type="file"
                    style={{ visibility: 'hidden' }}
                    onChange={handleUpload}
                    onClick={(e) => (e.target.value = null)}
                  />
                </div>

                <input
                  name="picture"
                  type="text"
                  style={{ display: 'none' }}
                  value={picturePreview || user.picture}
                  onChange={() => {}}
                  ref={register({ required: true })}
                />
                {errors.hasOwnProperty('picture') ? (
                  <FormHelperText error style={{ marginBottom: 16 }}>
                    Foto tidak boleh kosong!
                  </FormHelperText>
                ) : null}
                {picturePreview || user.picture ? (
                  <Avatar
                    variant="square"
                    style={{ width: '100%', height: 'auto' }}
                    alt="Uploads"
                    src={getImage(picturePreview || user.picture)}
                  />
                ) : null}
                <Divider style={{ margin: '32px 0 16px' }} />
                <div style={{ background: '#fdcfcf', padding: 16 }}>
                  <Typography variant="h6">Admin Area</Typography>
                  <FormControl style={{ minWidth: '100%' }} margin="normal">
                    <InputLabel shrink>Keaktifan Jemaat</InputLabel>
                    <Controller
                      as={
                        <Select>
                          <MenuItem value="VERY_ACTIVE">Sangat Aktif</MenuItem>
                          <MenuItem value="ACTIVE">Aktif</MenuItem>
                          <MenuItem value="NORMAL">Normal</MenuItem>
                          <MenuItem value="PASSIVE">Pasif</MenuItem>
                          <MenuItem value="VERY_PASSIVE">Sangat Pasif</MenuItem>
                        </Select>
                      }
                      name="user_activity"
                      control={control}
                      onChange={([selected]) => {
                        return selected.target.value;
                      }}
                      defaultValue={user.user_activity}
                    />
                  </FormControl>
                  <FormControl style={{ minWidth: '100%' }} margin="normal">
                    <Typography variant="body2">Jemaat Almarhum</Typography>
                    <Controller
                      as={
                        <DatePicker
                          format="dd MMM yyyy"
                          label="Meninggal pada"
                        />
                      }
                      name="dead_at"
                      control={control}
                      onChange={([selected]) => {
                        return format(selected, 'MM/dd/yyyy');
                      }}
                      defaultValue={
                        user.dead_at
                          ? format(new Date(user.dead_at), 'MM/dd/yyyy')
                          : null
                      }
                    />
                  </FormControl>
                </div>
                <Divider style={{ margin: '32px 0 16px' }} />
                <Button
                  onClick={() => history.push('/d/jemaat')}
                  style={{ marginRight: 8 }}
                  variant="outlined"
                >
                  Kembali
                </Button>
                <Button variant="contained" color="primary" type="submit">
                  Simpan
                </Button>
              </form>
            </Paper>
          </React.Fragment>
        ) : null}
      </Grid>
      <Grid item md={5}>
        <Paper style={{ padding: 16, marginBottom: 32 }}>
          <Typography
            variant="body1"
            style={{ margin: '0 0 16px', fontWeight: 700 }}
          >
            Alamat Jemaat
          </Typography>
          <Divider style={{ marginBottom: 16 }} />
          {user && user.address ? (
            <AddressCard
              id={user.address.id}
              name={user.address.name}
              address={user.address.address}
              province={user.address.province}
              regency={user.address.regency}
              district={user.address.district}
              villages={user.address.villages}
              latitude={user.address.latitude}
              longitude={user.address.longitude}
            />
          ) : null}
        </Paper>

        <RelationshipCard userID={match.params.id} />

        <CommisionCard userID={match.params.id} />

        {user ? <OwnerCard userID={user.id} owner={user.owner} /> : null}
      </Grid>
    </Grid>
  );
};

export default connect((state) => {
  return {
    user: state.user.detail,
    loading: state.user.loading,
  };
})(EditJemaat);
