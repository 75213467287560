import api from '../../utils/api';
import * as c from './constants';
import { toggleSnackbar } from '../ui/actions';

export const fetchOptions = (pollID) => {
  return async (dispatch) => {
    try {
      dispatch({ type: c.OPTION_LOADING });
      const { data: res } = await api.get(`/polls/${pollID}/options`);
      dispatch({
        type: c.OPTIONS_FULFILLED,
        payload: res.data,
      });
    } catch (error) {
      console.error(error);
      dispatch({ type: c.OPTIONS_FULFILLED, payload: [] });
    }
  };
};

export const createOption = (pollID, data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: c.OPTION_LOADING });
      await api.post(`/polls/${pollID}/options`, data);
      dispatch(fetchOptions(pollID));
      dispatch(toggleSnackbar('Data berhasil disimpan.'));
    } catch (error) {
      console.error(error);
      dispatch(toggleSnackbar('Gagal menyimpan data.'));
    }
  };
};

export const fetchOption = (pollID, optionID) => {
  return async (dispatch) => {
    try {
      dispatch({ type: c.OPTION_LOADING });
      const { data: res } = await api.get(
        `/polls/${pollID}/options/${optionID}`,
      );
      dispatch({
        type: c.OPTION_FULFILLED,
        payload: res.data,
      });
    } catch (error) {
      console.error(error);
      dispatch({ type: c.OPTION_FULFILLED, payload: null });
    }
  };
};

export const updateOption = (pollID, optionID, data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: c.OPTION_LOADING });
      await api.post(`/polls/${pollID}/options/${optionID}`, data);
      dispatch(fetchOption(pollID, optionID));
      dispatch(toggleSnackbar('Data berhasil disimpan.'));
    } catch (error) {
      console.error(error);
      dispatch(toggleSnackbar('Gagal menyimpan data.'));
    }
  };
};

export const deleteOptions = (pollID, optionIDs, history) => {
  return async (dispatch) => {
    try {
      await api.delete(`/polls/${pollID}/options`, {
        data: optionIDs,
      });

      dispatch(toggleSnackbar('Data berhasil dihapus.'));
      history.goBack();
    } catch (error) {
      console.error(error);
      dispatch(toggleSnackbar('Gagal menghapus data.'));
    }
  };
};
