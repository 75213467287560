import React from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';
import {
  Dialog,
  Divider,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  FormControl,
  FormHelperText,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import { actions } from '../../../reducers';
import api from '../../../utils/api';

const AddVoter = ({ dispatch, open, handleClose, namespaceID }) => {
  const { handleSubmit, register, errors, setValue } = useForm();
  const [options, setOptions] = React.useState([]);

  React.useEffect(() => {
    register('user_id');
    register('volunteer_id');
  }, [register]);

  const fetchData = debounce((name) => {
    api
      .get(`/users/search?name=${name}`)
      .then(({ data: res }) => {
        setOptions(res.data);
      })
      .catch(() => setOptions([]));
  }, 400);

  const handleSearchUser = ({ target }) => {
    if (target.value) {
      fetchData(target.value);
    } else {
      setOptions([]);
    }
  };

  const onSubmit = (values) => {
    dispatch(actions.inviteVoter(namespaceID, values));
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Undang Partisipan</DialogTitle>
      <Divider style={{ marginBottom: 16 }} />
      <DialogContent style={{ width: 500 }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div style={{ marginBottom: 16 }}>
            <FormControl fullWidth>
              <Autocomplete
                onChange={(_, selected) => {
                  if (selected) {
                    setValue('user_id', selected.id);
                  }
                }}
                getOptionSelected={(option, value) =>
                  option.name === value.name
                }
                getOptionLabel={(option) => option.name}
                options={options}
                error={errors.hasOwnProperty('user_id')}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Ketik nama jemaat..."
                    fullWidth
                    variant="outlined"
                    onChange={handleSearchUser}
                    InputProps={params.InputProps}
                  />
                )}
              />
            </FormControl>
          </div>
          <div style={{ marginBottom: 16 }}>
            <FormControl fullWidth>
              <Autocomplete
                onChange={(_, selected) => {
                  if (selected) {
                    setValue('volunteer_id', selected.id);
                  }
                }}
                getOptionSelected={(option, value) =>
                  option.name === value.name
                }
                getOptionLabel={(option) => option.name}
                options={options}
                error={errors.hasOwnProperty('volunteer_id')}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Ketik nama relawan..."
                    fullWidth
                    variant="outlined"
                    onChange={handleSearchUser}
                    InputProps={params.InputProps}
                  />
                )}
              />
            </FormControl>
            <FormHelperText>
              Isi nama relawan jika partisipan membutuhkan relawan
            </FormHelperText>
          </div>
          <Divider />
          <div style={{ margin: '16px 0' }}>
            <Button variant="contained" color="primary" type="submit">
              Undang
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default connect()(AddVoter);
