import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';

import { Snackbar, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import Home from './pages/Home';
import Maps from './pages/Maps';
import { ListJemaat, EditJemaat } from './pages/Jemaat';
import { ListKomisi, EditKomisi } from './pages/Commision';
import { ListWorship, EditWorship } from './pages/Worship';
import { ListSchedule, EditSchedule } from './pages/Schedule';
import {
  ListNamespace,
  EditNamespace,
  EditPoll,
  EditOption,
} from './pages/Polls';

import { PrimaryNavigation } from './components';

import { actions } from './reducers';

const App = ({ isOpen, message, dispatch }) => {
  const handleClose = () => {
    dispatch(actions.toggleSnackbar());
  };

  return (
    <React.Fragment>
      <PrimaryNavigation title="Beranda">
        <Switch>
          <Route exact path="/d" component={Home} />
          <Route exact path="/d/maps" component={Maps} />
          <Route exact path="/d/jemaat" component={ListJemaat} />
          <Route exact path="/d/jemaat/:id" component={EditJemaat} />
          <Route exact path="/d/komisi" component={ListKomisi} />
          <Route exact path="/d/komisi/:id" component={EditKomisi} />
          <Route exact path="/d/kebaktian" component={ListWorship} />
          <Route exact path="/d/kebaktian/:id" component={EditWorship} />
          <Route exact path="/d/jadwal" component={ListSchedule} />
          <Route exact path="/d/jadwal/:id" component={EditSchedule} />
          <Route exact path="/d/poll-namespace" component={ListNamespace} />
          <Route exact path="/d/poll-namespace/:id" component={EditNamespace} />
          <Route
            exact
            path="/d/poll-namespace/:id/polls/:poll_id"
            component={EditPoll}
          />
          <Route
            exact
            path="/d/poll-namespace/:id/polls/:poll_id/options/:option_id"
            component={EditOption}
          />
        </Switch>
      </PrimaryNavigation>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={isOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        message={message}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </React.Fragment>
  );
};

export default connect((state) => {
  return {
    isOpen: !!state.ui.message,
    message: state.ui.message,
  };
})(App);
