import React from 'react';
import get from 'lodash/get';
import { connect } from 'react-redux';
import { Grid, Paper, Typography, Divider } from '@material-ui/core';
import { Pie, Line, Doughnut, HorizontalBar } from 'react-chartjs-2';
import s from 'styled-components';

import PersonIcon from '@material-ui/icons/Person';
import GroupIcon from '@material-ui/icons/Group';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import PersonAddDisabledIcon from '@material-ui/icons/PersonAddDisabled';

import { actions } from '../../reducers';
import { Statistic, Table } from '../../components';
import { columns } from './utils';

const createAttendanceChart = (attendances) => {
  if (!attendances) {
    return { labels: [], datasets: [] };
  }
  const { labels, legends, datasets } = attendances;

  const options = {
    borderWidth: 1,
    lineTension: 0.1,
  };

  const color = {
    0: {
      backgroundColor: 'rgba(62, 81, 181,0.1)',
      borderColor: 'rgba(62, 81, 181, 1)',
    },
    1: {
      backgroundColor: 'rgba(186, 241, 161,0.1)',
      borderColor: 'rgba(186, 241, 161, 1)',
    },
    2: {
      backgroundColor: 'rgba(255,99,132,0.1)',
      borderColor: 'rgba(255,99,132, 1)',
    },
    3: {
      backgroundColor: 'rgba(68,142,246,0.1)',
      borderColor: 'rgba(68,142,246, 1)',
    },
    4: {
      backgroundColor: 'rgba(67,160,71,0.1)',
      borderColor: 'rgba(67,160,71, 1)',
    },
    5: {
      backgroundColor: 'rgba(117, 218, 173,0.1)',
      borderColor: 'rgba(117, 218, 173, 1)',
    },
    6: {
      backgroundColor: 'rgba(139,0,139,0.1)',
      borderColor: 'rgba(139,0,139, 1)',
    },
  };

  return {
    labels: labels,
    datasets: (legends || []).map((legend, idx) => {
      return {
        ...options,
        ...color[idx],
        label: legend,
        data: (datasets[legend] || []).map((d) => d.count),
      };
    }),
  };
};

const IconWrapper = s.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  text-align: center;
  background: ${(props) => props.background};
`;

const topStatsIcon = {
  'Total Jemaat': (
    <IconWrapper background="#e53935">
      <PersonIcon style={{ fontSize: 45, color: '#fff' }} />
    </IconWrapper>
  ),
  'Total Persekutuan': (
    <IconWrapper background="#43a047">
      <GroupIcon style={{ fontSize: 45, color: '#fff' }} />
    </IconWrapper>
  ),
  'Jemaat Tetap': (
    <IconWrapper background="#36A2EB">
      <PersonAddIcon style={{ fontSize: 45, color: '#fff' }} />
    </IconWrapper>
  ),
  'Jemaat Simpatisan': (
    <IconWrapper background="#3f51b5">
      <PersonAddDisabledIcon style={{ fontSize: 45, color: '#fff' }} />
    </IconWrapper>
  ),
};

const chartColors = [
  '#F44336',
  '#8BC34A',
  '#03A9F4',
  '#FF5722',
  '#E91E63',
  '#673AB7',
  '#CDDC39',
  '#9C27B0',
  '#311B92',
  '#3F51B5',
  '#2196F3',
  '#00BCD4',
  '#009688',
  '#4CAF50',
  '#FFEB3B',
  '#FFC107',
  '#FF9800',
  '#795548',
  '#757575',
];

const HomePage = ({
  overview,
  pagination,
  dispatch,
  attendances,
  birthday,
}) => {
  const [page, setPage] = React.useState(1);
  React.useEffect(() => {
    dispatch(actions.fetchStatistics('overview'));
    dispatch(actions.fetchStatistics('attendances'));
  }, []); // eslint-disable-line

  React.useEffect(() => {
    dispatch(actions.fetchBirthday(page));
  }, [page]); // eslint-disable-line

  const handlePageChange = (_, page) => {
    setPage(page + 1);
  };

  return (
    <div style={{ marginTop: 16 }}>
      <Typography variant="h5">Overview</Typography>
      <Grid container spacing={3} style={{ marginTop: 16 }}>
        {get(overview, 'top_statistic', []).map((ts, idx) => {
          return (
            <Grid key={idx} item md={3}>
              <Statistic {...ts} icon={topStatsIcon[ts.title]} />
            </Grid>
          );
        })}
      </Grid>
      <Grid container spacing={3} style={{ marginTop: 16 }}>
        <Grid item md={6}>
          <Paper
            style={{
              position: 'relative',
              maxHeight: 530,
              padding: '16px 16px 64px',
            }}
          >
            <Typography
              gutterBottom
              variant="body1"
              style={{ fontWeight: 700 }}
            >
              Statistik Kehadiran
            </Typography>
            <Divider style={{ marginBottom: 16 }} />
            <Line
              data={createAttendanceChart(attendances)}
              options={{
                maintainAspectRatio: false,
              }}
            />
          </Paper>
        </Grid>
        <Grid item md={6}>
          <Paper style={{ padding: 16 }}>
            <Typography
              gutterBottom
              variant="body1"
              style={{ fontWeight: 700 }}
            >
              Anggota Tiap Persekutuan
            </Typography>
            <Divider style={{ marginBottom: 16 }} />
            <HorizontalBar
              options={{
                legend: {
                  display: false,
                },
              }}
              data={{
                labels: get(overview, 'commision_member', []).map(
                  (s) => s.title
                ),
                datasets: [
                  {
                    data: get(overview, 'commision_member', []).map(
                      (s) => s.count
                    ),
                    backgroundColor: chartColors,
                  },
                ],
              }}
            />
          </Paper>
        </Grid>
        <Grid item md={3}>
          <Paper style={{ padding: 16 }}>
            <Typography
              gutterBottom
              variant="body1"
              style={{ fontWeight: 700 }}
            >
              Jenis Kelamin
            </Typography>
            <Divider style={{ marginBottom: 16 }} />
            <Doughnut
              data={{
                labels: get(overview, 'gender', []).map((s) => s.title),
                datasets: [
                  {
                    data: get(overview, 'gender', []).map((s) => s.count),
                    backgroundColor: chartColors,
                  },
                ],
              }}
            />
          </Paper>
        </Grid>
        <Grid item md={3}>
          <Paper style={{ padding: 16 }}>
            <Typography
              gutterBottom
              variant="body1"
              style={{ fontWeight: 700 }}
            >
              Status Perkawinan
            </Typography>
            <Divider style={{ marginBottom: 16 }} />
            <Pie
              data={{
                labels: get(overview, 'marital', []).map((s) => s.title),
                datasets: [
                  {
                    data: get(overview, 'marital', []).map((s) => s.count),
                    backgroundColor: chartColors,
                  },
                ],
              }}
            />
          </Paper>
        </Grid>
        <Grid item md={3}>
          <Paper style={{ padding: 16 }}>
            <Typography
              gutterBottom
              variant="body1"
              style={{ fontWeight: 700 }}
            >
              Status Baptisan
            </Typography>
            <Divider style={{ marginBottom: 16 }} />
            <Doughnut
              data={{
                labels: get(overview, 'baptis', []).map((s) => s.title),
                datasets: [
                  {
                    data: get(overview, 'baptis', []).map((s) => s.count),
                    backgroundColor: chartColors,
                  },
                ],
              }}
            />
          </Paper>
        </Grid>
        <Grid item md={3}>
          <Paper style={{ padding: 16 }}>
            <Typography
              gutterBottom
              variant="body1"
              style={{ fontWeight: 700 }}
            >
              Segmentasi Usia
            </Typography>
            <Divider style={{ marginBottom: 16 }} />
            <Pie
              data={{
                labels: get(overview, 'age', []).map((s) => s.title),
                datasets: [
                  {
                    data: get(overview, 'age', []).map((s) => s.count),
                    backgroundColor: chartColors,
                  },
                ],
              }}
            />
          </Paper>
        </Grid>
        <Grid item md={6}>
          <Paper style={{ padding: 16 }}>
            <Typography
              gutterBottom
              variant="body1"
              style={{ fontWeight: 700 }}
            >
              Jemaat Berulang Tahun Bulan Ini
            </Typography>
            <Table
              dense
              data={birthday}
              columns={columns}
              stickyHeader
              pagination={{
                perPage: 10,
                page: page - 1,
                count: pagination ? pagination.count : 1,
                onChangePage: handlePageChange,
              }}
            />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default connect((state) => {
  return {
    loading: false,
    overview: state.stats.overview,
    attendances: state.stats.attendances,
    birthday: state.stats.birthday,
    pagination: state.stats.pagination,
  };
})(HomePage);
