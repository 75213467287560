import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { CookiesProvider } from 'react-cookie';
import CssBaseline from '@material-ui/core/CssBaseline';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import DateFnsUtils from '@date-io/date-fns';
import idLocale from 'date-fns/locale/id';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { GoogleOAuthProvider } from '@react-oauth/google';

import config from './config';
import App from './App';
import Login from './pages/Login';
import { PrivateRoute } from './components';
import * as serviceWorker from './serviceWorker';
import configureStore from './store';

const store = configureStore();

const Container = () => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={idLocale}>
      <CookiesProvider>
        <CssBaseline />
        <Provider store={store}>
          <GoogleOAuthProvider clientId={config.GOOGLE_CLIENT_ID}>
            <Router>
              <Switch>
                <Route exact path="/" component={Login} />
                <PrivateRoute path="/d" component={App} />
              </Switch>
            </Router>
          </GoogleOAuthProvider>
        </Provider>
      </CookiesProvider>
    </MuiPickersUtilsProvider>
  );
};

const renderApp = () => {
  ReactDOM.render(<Container />, document.getElementById('root'));
};

serviceWorker.unregister();

renderApp();

if (process.env.NODE_ENV !== 'production' && module.hot) {
  module.hot.accept('./App', renderApp);
}
