import React from 'react';
import startCase from 'lodash/startCase';
import GoogleMapReact from 'google-map-react';
import {
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  Button,
  Typography,
} from '@material-ui/core';
import RoomIcon from '@material-ui/icons/Room';
import s from 'styled-components';

export const getMap = (type, { lat, lng }) => {
  const directionURL = `https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}`;
  const locationURL = `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`;

  const win = window.open(
    new URL(type === 'direction' ? directionURL : locationURL),
    '_blank',
  );
  win.focus();
};

const MarkerWrapper = s.div`
  position: absolute; 
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  left: 5px;
  top: -10px;
  z-index: 9999;
`;

const titleCase = (str) => {
  return startCase(str.toLowerCase());
};

const MapMarker = ({ text }) => (
  <MarkerWrapper>
    <RoomIcon color="error" />
    <div>{text}</div>
  </MarkerWrapper>
);

function MediaCard({
  name,
  address,
  province,
  regency,
  district,
  villages,
  latitude,
  longitude,
}) {
  return (
    <Card style={{ marginBottom: 16, width: '100%' }}>
      <CardActionArea>
        <div style={{ height: '200px', width: '100%' }}>
          <GoogleMapReact
            bootstrapURLKeys={{
              key: 'AIzaSyC2kj2L7wUjVwDrRCP4k5o3RF5ZU5r6nwI',
            }}
            defaultCenter={[latitude, longitude]}
            defaultZoom={12}
          >
            <MapMarker lat={latitude} lng={longitude} text={name} />
          </GoogleMapReact>
        </div>
        <CardContent>
          <Typography gutterBottom variant="h6" component="h2">
            {name}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {`${address}, ${titleCase(district)}, ${titleCase(
              regency,
            )}, ${titleCase(villages)}, ${province}`}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button
          size="small"
          color="primary"
          variant="contained"
          onClick={() => getMap('preview', { lat: latitude, lng: longitude })}
        >
          Buka di Google Maps
        </Button>
        <Button
          size="small"
          color="secondary"
          variant="contained"
          onClick={() => getMap('direction', { lat: latitude, lng: longitude })}
        >
          Dapatkan Petunjuk Arah
        </Button>
      </CardActions>
    </Card>
  );
}

export default MediaCard;
