import React from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import {
  Dialog,
  Divider,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  FormHelperText,
  Avatar,
} from '@material-ui/core';

import { actions } from '../../../reducers';
import { getImage } from '../../../utils/image';
import uploadImage from '../../../utils/uploadImage';

const AddOption = ({ dispatch, open, handleClose, pollID }) => {
  const { handleSubmit, register, errors, setValue } = useForm();
  const [picturePreview, setPreview] = React.useState('');
  const [isUploading, setUploadStatus] = React.useState(false);

  const onSubmit = (values) => {
    dispatch(actions.createOption(pollID, values));
    handleClose();
  };

  const handleUpload = ({ target }) => {
    setUploadStatus(true);
    uploadImage(target.files[0], { folder: 'poll_options' })
      .then((res) => {
        setValue('picture', res.secure_url);
        setPreview(res.secure_url);
      })
      .catch(() => {
        dispatch(actions.toggleSnackbar('Gagal menunggah foto!'));
      })
      .finally(() => {
        setUploadStatus(false);
      });
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Buat Baru</DialogTitle>
      <Divider style={{ marginBottom: 16 }} />
      <DialogContent style={{ width: 500 }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div style={{ marginBottom: 16 }}>
            <TextField
              fullWidth
              label="Nama Pilihan"
              name="name"
              size="small"
              variant="outlined"
              inputRef={register({ required: true })}
              error={errors.hasOwnProperty('name')}
            />
          </div>
          <div style={{ marginBottom: 16 }}>
            <TextField
              fullWidth
              label="Nama Pilihan (dalam Mandarin)"
              name="name_zh"
              size="small"
              variant="outlined"
              inputRef={register}
              error={errors.hasOwnProperty('name_zh')}
            />
          </div>
          <div style={{ marginBottom: 16 }}>
            <TextField
              fullWidth
              label="Deskripsi"
              name="description"
              size="small"
              variant="outlined"
              multiline
              rows={4}
              inputRef={register}
              error={errors.hasOwnProperty('description')}
            />
          </div>
          <div style={{ marginBottom: 16 }}>
            <TextField
              fullWidth
              label="Deskripsi (dalam Mandarin)"
              name="description_zh"
              size="small"
              variant="outlined"
              multiline
              rows={4}
              inputRef={register}
              error={errors.hasOwnProperty('description_zh')}
            />
          </div>
          <div style={{ margin: '16px 0' }}>
            <label htmlFor="input-file">
              <Button variant="contained" color="primary" component="span">
                {isUploading ? 'Sedang mengunggah...' : 'Upload Foto'}
              </Button>
            </label>
            <input
              accept="image/*"
              id="input-file"
              type="file"
              style={{ visibility: 'hidden' }}
              onChange={handleUpload}
              onClick={(e) => (e.target.value = null)}
            />
          </div>
          <input
            name="picture"
            type="text"
            style={{ display: 'none' }}
            value={picturePreview}
            onChange={() => {}}
            ref={register({ required: true })}
          />
          {errors.hasOwnProperty('picture') ? (
            <FormHelperText error style={{ marginBottom: 16 }}>
              Foto tidak boleh kosong!
            </FormHelperText>
          ) : null}
          {picturePreview ? (
            <Avatar
              variant="square"
              style={{ width: '100%', height: 'auto' }}
              alt="Uploads"
              src={getImage(picturePreview)}
            />
          ) : null}
          <Divider />
          <div style={{ margin: '16px 0' }}>
            <Button variant="contained" color="primary" type="submit">
              Simpan
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default connect()(AddOption);
