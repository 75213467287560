import api from '../../utils/api';
import * as c from './constants';
import { toggleSnackbar } from '../ui/actions';

const defaultParams = {
  page: 1,
  per_page: 10,
  name: '',
};

export const fetchWorships = (filter = defaultParams) => {
  return async (dispatch) => {
    try {
      const mapper = {
        per_page: 'limit',
      };

      const query = Object.keys(filter)
        .filter((k) => filter[k])
        .map((k) => `${mapper.hasOwnProperty(k) ? mapper[k] : k}=${filter[k]}`)
        .join('&');

      dispatch({ type: c.WORSHIP_LOADING });
      const { data: res } = await api.get(`/worships/?${query}`);
      dispatch({
        type: c.WORSHIPS_FULFILLED,
        payload: res.data,
        pagination: res.pagination,
      });
    } catch (error) {
      console.error(error);
      dispatch({ type: c.WORSHIPS_FULFILLED, payload: [] });
    }
  };
};

export const fetchWorship = (worshipID) => {
  return async (dispatch) => {
    try {
      dispatch({ type: c.WORSHIP_LOADING });
      const { data: res } = await api.get(`/worships/${worshipID}`);
      dispatch({ type: c.WORSHIP_FULFILLED, payload: res.data });
    } catch (error) {
      console.error(error);
      dispatch({ type: c.WORSHIP_FULFILLED, payload: null });
    }
  };
};

export const createWorship = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: c.WORSHIP_LOADING });
      await api.post('/worships/', data);
      dispatch(fetchWorships());
      dispatch(toggleSnackbar('Data berhasil disimpan.'));
    } catch (error) {
      console.error(error);
      dispatch(toggleSnackbar('Gagal menyimpan data.'));
    }
  };
};

export const updateWorship = (worshipID, data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: c.WORSHIP_LOADING });
      await api.put(`/worships/${worshipID}`, data);
      dispatch(fetchWorship(worshipID));
      dispatch(toggleSnackbar('Data berhasil disimpan.'));
    } catch (error) {
      console.error(error);
      dispatch(toggleSnackbar('Gagal menyimpan data.'));
    }
  };
};

const defaultScheduleParams = {
  page: 1,
  per_page: 10,
};

export const fetchScheduleByWorshipID = (
  worshipID,
  filter = defaultScheduleParams,
) => {
  return async (dispatch) => {
    try {
      const mapper = {
        per_page: 'limit',
      };

      const query = Object.keys(filter)
        .filter((k) => filter[k])
        .map((k) => `${mapper.hasOwnProperty(k) ? mapper[k] : k}=${filter[k]}`)
        .join('&');

      dispatch({ type: c.WORSHIP_SCHEDULES_LOADING });
      const { data: res } = await api.get(
        `/worships/${worshipID}/schedules?${query}`,
      );
      dispatch({
        type: c.WORSHIP_SCHEDULES_FULFILLED,
        payload: res.data,
        pagination: res.pagination,
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: c.WORSHIP_SCHEDULES_FULFILLED,
        payload: [],
        pagination: null,
      });
    }
  };
};
