import * as c from './constants';

export const initialState = {
  loading: false,
  data: [],
  detail: null,
};

const optionReducer = (state = initialState, action) => {
  switch (action.type) {
    case c.OPTION_LOADING:
      return { ...state, loading: true, data: [], detail: null };
    case c.OPTIONS_FULFILLED:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case c.OPTION_FULFILLED:
      return { ...state, loading: false, detail: action.payload };
    default:
      return state;
  }
};

export default optionReducer;
